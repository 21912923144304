import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Col, { ColProps } from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Stack from "react-bootstrap/Stack";

import Metric from "./Metric";
import "./MetricNavItem.scss";

type MetricNavItemProps = {
  eventKey: string;
  label: string;
  value?: React.ReactNode;
  description?: string;
  icon: IconDefinition;
} & ColProps;

function MetricNavItem({ eventKey, label, value, description, icon, ...colProps }: MetricNavItemProps) {
  return (
    <Nav.Item as={Col} className="MetricNavItem" {...colProps}>
      <Nav.Link eventKey={eventKey}>
        <Stack direction="horizontal" className="align-items-start">
          <Metric label={label} value={value} help={description} />
          <div className="ms-auto">
            <div className="icon-circle">
              <FontAwesomeIcon icon={icon} />
            </div>
          </div>
        </Stack>
      </Nav.Link>
    </Nav.Item>
  );
}

export default MetricNavItem;
