import { Field, FieldInputProps, Form, Formik } from "formik";
import { DateTime } from "luxon";
import Button from "react-bootstrap/Button";
import BootstrapForm from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import { parseCustomPeriod } from "../utils/period";

function DateTimeInput(props: FieldInputProps<string>) {
  return <BootstrapForm.Control type="datetime-local" {...props} />;
}

type CustomPeriodModalProps = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  period: string;
  setPeriod: (period: string) => void;
};

function CustomPeriodModal({ show, setShow, period, setPeriod }: CustomPeriodModalProps) {
  const customPeriod = parseCustomPeriod(period);
  const inputFormat = "yyyy-MM-dd'T'HH:mm";
  const outputFormat = "yyyy-MM-dd'T'HH:mm:00";
  const now = DateTime.now();

  return (
    <Formik
      initialValues={{
        start: customPeriod?.start.toFormat(inputFormat) || now.minus({ hours: 1 }).toFormat(inputFormat),
        end: customPeriod?.end.toFormat(inputFormat) || now.toFormat(inputFormat),
      }}
      enableReinitialize
      validate={(values) => {
        const now = DateTime.now();
        const start = DateTime.fromISO(values.start);
        const end = DateTime.fromISO(values.end);
        const errors: Record<string, string> = {};
        if (start < now.minus({ months: 12 })) {
          errors.start = "Start must be within the last 12 months";
        } else if (start > now) {
          errors.start = "Start must be in the past";
        }
        if (start >= end) {
          errors.end = "End must be after start";
        } else if (end > now.plus({ days: 31 })) {
          errors.end = "End is too far in the future";
        } else if (end.diff(start, "minutes").minutes < 1) {
          errors.end = "Period must be at least 1 minute long";
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          const start = DateTime.fromISO(values.start).toFormat(outputFormat);
          const end = DateTime.fromISO(values.end).toFormat(outputFormat);
          setPeriod(`${start}|${end}`);
          setShow(false);
          resetForm();
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ errors, handleSubmit, isSubmitting, resetForm, isValid }) => (
        <Modal show={show} onHide={() => setShow(false)} onExited={() => resetForm()}>
          <Modal.Header closeButton>
            <Modal.Title>Custom period</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <BootstrapForm.Group controlId="formStart">
                <BootstrapForm.Label>Start</BootstrapForm.Label>
                <Field name="start" as={DateTimeInput} isInvalid={!!errors.start} />
                <BootstrapForm.Control.Feedback type="invalid">{errors.start}</BootstrapForm.Control.Feedback>
              </BootstrapForm.Group>
              <BootstrapForm.Group controlId="formEnd" className="mt-4">
                <BootstrapForm.Label>End</BootstrapForm.Label>
                <Field name="end" as={DateTimeInput} isInvalid={!!errors.end} />
                <BootstrapForm.Control.Feedback type="invalid">{errors.end}</BootstrapForm.Control.Feedback>
              </BootstrapForm.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
              Cancel
            </Button>
            <Button onClick={() => handleSubmit()} disabled={isSubmitting || !isValid}>
              Apply
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
}

export default CustomPeriodModal;
