import { useQuery } from "@tanstack/react-query";
import { BarController, BarElement, CategoryScale, Chart as ChartJS, Title, Tooltip, TooltipItem } from "chart.js";
import "chartjs-adapter-luxon";
import { memo } from "react";
import { Bar } from "react-chartjs-2";

import { AppEnvItem, ListAppsResponseItem } from "../backend";
import { useGlobal } from "../contexts/GlobalContext";
import { EndpointError } from "../types/Endpoint";
import { getColor } from "../utils/colors";

ChartJS.register(BarController, BarElement, CategoryScale, Title, Tooltip);

type ErrorsByConsumerBarChartProps = {
  app: ListAppsResponseItem;
  env?: AppEnvItem;
  consumerGroupId?: number;
  endpointError: EndpointError;
  period: string;
};

function ErrorsByConsumerBarChart({ app, env, consumerGroupId, endpointError, period }: ErrorsByConsumerBarChartProps) {
  const { backendClient, timezone } = useGlobal();

  const queryParams = {
    appId: app.id,
    appEnv: env?.slug,
    consumerGroupId,
    method: endpointError.method,
    path: endpointError.path,
    statusCode: endpointError.status_code,
    period,
    timezone,
  };
  const query = useQuery({
    queryKey: ["errorsByConsumerChart", queryParams],
    queryFn: () => backendClient!.errors.getErrorsByConsumerChart(queryParams),
    enabled: !!backendClient,
  });

  if (query.isSuccess) {
    const dangerColor = getColor("danger");
    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      font: {
        family: "DM Sans",
      },
      indexAxis: "y" as const,
      scales: {
        x: {
          stacked: true,
          grid: {
            display: true,
            color: "#faf9fb",
          },
          border: {
            display: false,
          },
          ticks: {
            font: {
              family: "DM Sans",
            },
          },
          display: true,
          beginAtZero: true,
          precision: 0,
        },
        y: {
          stacked: true,
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
          ticks: {
            font: {
              family: "DM Sans",
              weight: "bold" as const,
            },
          },
        },
      },
      animation: {
        duration: 0,
      },
      plugins: {
        tooltip: {
          mode: "index" as const,
          position: "nearest" as const,
          callbacks: {
            label: function (context: TooltipItem<"bar">) {
              const value = context.parsed.x.toLocaleString();
              return ` ${value}`;
            },
          },
        },
        legend: {
          display: false,
        },
      },
    };
    const chartData = {
      datasets: [
        {
          data: query.data.consumer_names.slice(0, 10).map((consumer_name, index) => ({
            y: consumer_name,
            x: query.data.request_counts[index],
          })),
          backgroundColor: endpointError.status_code >= 500 ? dangerColor : "#dea6a6",
          hoverBackgroundColor: endpointError.status_code >= 500 ? dangerColor : "#dea6a6",
        },
      ],
    };
    if (query.data.consumer_ids.length === 0) {
      return <div className="text-center py-2">No occurrences for identified consumers in the selected period.</div>;
    } else {
      const chartHeight = 40 + Math.min(query.data.consumer_ids.length, 10) * 40;
      return (
        <div style={{ height: chartHeight }}>
          <Bar data={chartData} options={chartOptions} />
        </div>
      );
    }
  } else {
    return <></>;
  }
}

export default memo(ErrorsByConsumerBarChart);
