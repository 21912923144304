import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Field, FieldProps, Form, Formik } from "formik";
import React, { useCallback } from "react";
import Button from "react-bootstrap/Button";
import BootstrapForm from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

import { ListConsumerGroupsResponseItem, ListConsumersWithTrafficResponseItem } from "../backend";
import Select from "../components/Select";
import Tooltip from "../components/Tooltip";
import { useGlobal } from "../contexts/GlobalContext";
import "./ConsumerFormModal.scss";

type ConsumerFormModalProps = {
  consumer?: ListConsumersWithTrafficResponseItem;
  setConsumer: React.Dispatch<React.SetStateAction<ListConsumersWithTrafficResponseItem | undefined>>;
};

interface ConsumerFormValues {
  name: string;
  consumer_group_id?: number;
}

function ConsumerFormModal({ consumer, setConsumer }: ConsumerFormModalProps) {
  const { activeApp, backendClient, isTeamAdmin, isDemo } = useGlobal();
  const queryClient = useQueryClient();

  const consumerGroupsQueryParams = { appId: activeApp?.id || 0 };
  const consumerGroupsQuery = useQuery({
    queryKey: ["consumerGroups", consumerGroupsQueryParams],
    queryFn: () => backendClient!.consumers.listConsumerGroups(consumerGroupsQueryParams),
    enabled: !!backendClient && !!activeApp,
  });
  const consumerGroupOptions =
    consumerGroupsQuery.data?.map((consumerGroup) => ({ label: consumerGroup.name, value: consumerGroup.id })) || [];

  const createConsumerGroup = useCallback(
    async (name: string) => {
      if (backendClient && activeApp && consumer) {
        const promise = backendClient.consumers.createConsumerGroup({
          appId: activeApp.id,
          requestBody: {
            name,
            consumer_ids: [consumer.id],
          },
        });
        toast.promise(promise, {
          error: "Failed to create consumer group.",
        });
        const newConsumerGroupId = await promise;
        const newConsumerGroup = { id: newConsumerGroupId, name: name.trim(), consumers_count: 0 };
        queryClient.setQueryData(
          ["consumerGroups", consumerGroupsQueryParams],
          (old: ListConsumerGroupsResponseItem[]) => [...old, newConsumerGroup],
        );
        return newConsumerGroupId;
      }
      return null;
    },
    [backendClient, activeApp, consumer, queryClient],
  );

  const validate = async (values: ConsumerFormValues) => {
    const errors: Partial<ConsumerFormValues> = {};
    if (!values.name || values.name.trim() === "") {
      errors.name = "Required";
    }
    return errors;
  };

  const updateConsumer = async (values: ConsumerFormValues) => {
    if (backendClient && activeApp && consumer) {
      const promise = backendClient.consumers.updateConsumer({
        appId: activeApp.id,
        consumerId: consumer.id,
        requestBody: {
          name: values.name,
          consumer_group_id: values.consumer_group_id,
        },
      });
      toast.promise(promise, {
        pending: "Updating consumer...",
        success: "Consumer updated!",
        error: "Failed to update consumer.",
      });
      await promise;
    }
  };

  if (!consumer) {
    return <></>;
  }

  return (
    <Formik
      initialValues={{ name: consumer.name, consumer_group_id: consumer.group?.id }}
      validate={validate}
      onSubmit={async (values, { setSubmitting }) => {
        if (!isTeamAdmin) {
          return;
        }
        try {
          await updateConsumer(values);
          ["consumers", "consumersWithTraffic", "consumerGroups", "consumerGroupsWithTraffic"].forEach((key) => {
            queryClient.invalidateQueries({ queryKey: [key, { appId: activeApp?.id || 0 }] });
          });
          setConsumer(undefined);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, isSubmitting, errors, touched, setFieldValue, resetForm }) => (
        <Modal
          className="ConsumerFormModal"
          show={consumer !== undefined}
          onHide={() => setConsumer(undefined)}
          onExited={() => resetForm()}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit consumer</Modal.Title>
          </Modal.Header>
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <BootstrapForm.Group controlId="formName">
                <BootstrapForm.Label>
                  Name <span className="text-danger">*</span>
                </BootstrapForm.Label>
                <Field name="name">
                  {({ field }: FieldProps<string>) => (
                    <BootstrapForm.Control
                      type="text"
                      placeholder="Consumer name"
                      maxLength={64}
                      autoComplete="off"
                      isInvalid={!!errors.name && !!touched.name}
                      {...field}
                    />
                  )}
                </Field>
                <BootstrapForm.Control.Feedback type="invalid">{errors.name}</BootstrapForm.Control.Feedback>
              </BootstrapForm.Group>

              <BootstrapForm.Group controlId="formConsumerGroupId" className="mt-4">
                <BootstrapForm.Label>Group</BootstrapForm.Label>
                <Field name="consumer_group_id">
                  {({ field }: FieldProps<number>) => (
                    <Select
                      options={consumerGroupOptions}
                      placeholder="Create group or select existing"
                      isSearchable
                      isClearable
                      onCreateOption={async (value) => {
                        const consumerGroupId = await createConsumerGroup(value);
                        setFieldValue(field.name, consumerGroupId);
                      }}
                      {...field}
                    />
                  )}
                </Field>
                <BootstrapForm.Control.Feedback type="invalid">
                  {errors.consumer_group_id}
                </BootstrapForm.Control.Feedback>
              </BootstrapForm.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setConsumer(undefined)}>
                Discard
              </Button>
              <Tooltip condition={!isTeamAdmin && isDemo} tooltip="Not allowed in demo">
                <Button type="submit" onClick={() => handleSubmit()} disabled={isSubmitting || !isTeamAdmin}>
                  Submit
                </Button>
              </Tooltip>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}

export default ConsumerFormModal;
