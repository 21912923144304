import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";

import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./TableCard.css";

export type SortBy = {
  column: string;
  direction: "asc" | "desc";
};

type ColumnHeaderProps = {
  name: string;
  sortBy?: SortBy;
  setSortBy?: (sortBy: SortBy) => void;
  defaultSortDirection?: "asc" | "desc";
  className?: string;
  children: React.ReactNode | React.ReactNode[];
  disabled?: boolean;
};

export function ColumnHeader({
  name,
  sortBy,
  setSortBy,
  defaultSortDirection = "desc",
  className,
  children,
  disabled,
}: ColumnHeaderProps) {
  if (disabled) {
    return <th className={className}>{children}</th>;
  }
  return (
    <th
      className={`${sortBy ? "cursor-pointer" : ""} ${className || ""}`}
      onClick={
        sortBy && setSortBy
          ? () =>
              setSortBy({
                column: name,
                direction: sortBy.column !== name ? defaultSortDirection : sortBy.direction === "desc" ? "asc" : "desc",
              })
          : undefined
      }
    >
      {children}
      {sortBy && (
        <span className={`small ms-icon ${sortBy.column !== name ? "invisible" : ""}`}>
          <FontAwesomeIcon icon={sortBy.direction === "asc" ? faCaretUp : faCaretDown} className="text-very-muted" />
        </span>
      )}
    </th>
  );
}

type TableCardProps = {
  children: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  hover?: boolean;
  responsive?: boolean;
  borderTop?: boolean;
} & React.ComponentPropsWithoutRef<typeof Card>;

function TableCard({
  children,
  header,
  footer,
  hover = true,
  responsive,
  borderTop = true,
  className,
  ...rest
}: TableCardProps) {
  const mergedClassName = `TableCard ${borderTop ? "bt" : ""} ${footer ? "" : "nofooter"} ${className || ""}`;
  return (
    <Card className={mergedClassName} {...rest}>
      {header && <Card.Header>{header}</Card.Header>}
      <Card.Body className="p-0">
        <Table hover={hover} responsive={responsive}>
          {children}
        </Table>
      </Card.Body>
      {footer && <Card.Footer>{footer}</Card.Footer>}
    </Card>
  );
}

export default TableCard;
