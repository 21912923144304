import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

import { useGlobal } from "../contexts/GlobalContext";

type UpgradePlanAlertProps = {
  variant?: string;
  upgradeButtonOnClick?: () => void;
  extraButtons?: React.ReactNode | React.ReactNode[];
  children: React.ReactNode;
  className?: string;
};

function UpgradePlanAlert({
  variant = "primary",
  upgradeButtonOnClick,
  extraButtons,
  children,
  className,
}: UpgradePlanAlertProps) {
  const { teamPlan } = useGlobal();
  const navigate = useNavigate();

  if (!upgradeButtonOnClick) {
    upgradeButtonOnClick = () => navigate("/settings?upgradePlan");
  }

  return (
    <Alert variant={variant} className={className}>
      {children}
      <div className="mt-4 d-flex gap-2">
        {teamPlan?.upgradable && (
          <Button variant={variant} onClick={upgradeButtonOnClick}>
            Upgrade plan
          </Button>
        )}
        {teamPlan && !teamPlan.upgradable && (
          <Button variant={variant} onClick={() => window.open("mailto:support@apitally.io")}>
            Contact us
          </Button>
        )}
        {extraButtons}
      </div>
    </Alert>
  );
}

export default UpgradePlanAlert;
