import { Field, FieldProps, Form, Formik } from "formik";
import React from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import BootstrapForm from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

import { ListAppsResponseItem } from "../backend";
import { useGlobal } from "../contexts/GlobalContext";

type AppDeleteModalProps = {
  app?: ListAppsResponseItem;
  setApp: React.Dispatch<React.SetStateAction<ListAppsResponseItem | undefined>>;
};

interface AppDeleteFormValues {
  name: string;
}

function AppDeleteModal({ app, setApp }: AppDeleteModalProps) {
  const { backendClient, refreshApps, refreshTeamPlan } = useGlobal();

  const validate = (values: AppDeleteFormValues) => {
    const errors: Partial<AppDeleteFormValues> = {};
    if (values.name.trim() !== app?.name) {
      errors.name = "Name does not match";
    }
    return errors;
  };

  const deleteApp = async () => {
    if (backendClient && app) {
      const promise = backendClient.apps.deleteApp({ appId: app.id });
      toast.promise(promise, {
        pending: "Deleting app...",
        success: "App deleted!",
        error: "Failed to delete app.",
      });
      await promise;
    }
  };

  return (
    <Formik
      initialValues={{ name: "" }}
      validate={validate}
      onSubmit={async (values, { setSubmitting }) => {
        await deleteApp();
        setSubmitting(false);
        setApp(undefined);
        refreshApps();
        refreshTeamPlan();
      }}
    >
      {({ handleSubmit, isSubmitting, errors, touched, resetForm }) => (
        <Modal show={app !== undefined} onHide={() => setApp(undefined)} onExited={() => resetForm()}>
          <Modal.Header closeButton>
            <Modal.Title>Delete app</Modal.Title>
          </Modal.Header>
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <Alert variant="danger">
                Are you sure you want to delete the app <strong>{app?.name}</strong>? This action cannot be undone. All
                data associated with this app will be deleted.
              </Alert>

              <BootstrapForm.Group controlId="formName">
                <BootstrapForm.Label className="fw-normal">
                  Enter the app name <strong>{app?.name}</strong> to confirm
                </BootstrapForm.Label>
                <Field name="name">
                  {({ field }: FieldProps<string>) => (
                    <BootstrapForm.Control
                      type="text"
                      placeholder={app?.name}
                      autoComplete="off"
                      isInvalid={!!errors.name && !!touched.name}
                      {...field}
                    />
                  )}
                </Field>
                <BootstrapForm.Control.Feedback type="invalid">{errors.name}</BootstrapForm.Control.Feedback>
              </BootstrapForm.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setApp(undefined)}>
                Cancel
              </Button>
              <Button type="submit" variant="danger" onClick={() => handleSubmit()} disabled={isSubmitting}>
                Delete
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}

export default AppDeleteModal;
