import { faCircle, faCircleCheck, faCircleDot, faCircleXmark, faClock } from "@fortawesome/free-regular-svg-icons";
import {
  faBell,
  faBellSlash,
  faBoxArchive,
  faCheck,
  faEye,
  faEyeSlash,
  faFilterCircleXmark,
  faRotateLeft,
  faRotateRight,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIsFetching, useQuery, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import capitalize from "lodash/capitalize";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Dropdown from "react-bootstrap/Dropdown";
import Placeholder from "react-bootstrap/Placeholder";
import Stack from "react-bootstrap/Stack";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { ListAlertsResponseItem } from "./backend";
import AppEnvBadge from "./components/AppEnvBadge";
import CustomDropdown from "./components/Dropdown";
import FilterBadge from "./components/FilterBadge";
import MainLayout from "./components/MainLayout";
import PageHeader from "./components/PageHeader";
import PageSubHeader from "./components/PageSubHeader";
import Pagination from "./components/Pagination";
import RefreshButton from "./components/RefreshButton";
import TableCard from "./components/TableCard";
import TableCellDropdown from "./components/TableCellDropdown";
import { useGlobal } from "./contexts/GlobalContext";
import AlertDetailsModal from "./modals/AlertDetailsModal";
import { formatDateTimeDiff, formatRelativeDateTime } from "./utils/datetime";

type AlertEventsProps = {
  alert: ListAlertsResponseItem;
};

function AlertEvents({ alert }: AlertEventsProps) {
  const { backendClient, timezone } = useGlobal();
  const [page, setPage] = useState(1);
  const [currentAlert, setCurrentAlert] = useState(alert);

  useEffect(() => {
    // Ensure page is reset to 1 when alert changes, before the query refetch is triggered
    setCurrentAlert(alert);
    setPage(1);
  }, [alert]);

  const eventsPerPage = 25;
  const offset = (page - 1) * eventsPerPage;
  const queryParams = { alertId: currentAlert.id, limit: eventsPerPage, offset };
  const query = useQuery({
    queryKey: ["alertEvents", queryParams],
    queryFn: () => backendClient!.alerts.listAlertEvents(queryParams),
    enabled: !!backendClient,
    refetchInterval: !currentAlert.resolved ? 60000 : undefined,
    placeholderData: (previousData, previousQuery) => {
      const previousQueryParams = previousQuery?.queryKey[1] as typeof queryParams | undefined;
      if (previousQueryParams?.alertId === currentAlert.id) {
        return previousData;
      }
    },
  });
  const eventCount = query.isSuccess ? query.data.total : 0;
  const numberOfPages = Math.ceil(eventCount / eventsPerPage) || 1;

  const eventRows = query.data?.items.map((event) => {
    const timestamp = DateTime.fromISO(event.timestamp, { zone: "utc" }).setZone(timezone);
    const statusIcon = event.status === "success" ? faCircleCheck : faCircleXmark;
    const statusClass = event.status === "success" ? "text-primary" : "text-danger";
    return (
      <tr key={event.timestamp}>
        <td width={40}>
          <FontAwesomeIcon icon={faClock} className="ms-2 text-very-muted" />
        </td>
        <td width={230}>{timestamp.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}</td>
        <td width={160} className={`${statusClass} text-nowrap`}>
          <FontAwesomeIcon icon={statusIcon} className="me-icon" />
          {capitalize(event.status)}
        </td>
        <td>{event.description}</td>
        <td className="pe-4 text-end text-very-muted">
          {event.count && event.count > 1 && `${event.count.toLocaleString()}x`}
        </td>
      </tr>
    );
  });

  const showingEventRange = {
    from: (page - 1) * eventsPerPage + 1,
    to: Math.min((page - 1) * eventsPerPage + eventsPerPage, eventCount),
  };
  const footer = (
    <Stack direction="horizontal">
      <div className="small text-muted">
        {!!eventRows && (
          <>
            Showing events {showingEventRange.from.toLocaleString()}-{showingEventRange.to.toLocaleString()} of{" "}
            {eventCount.toLocaleString()}
          </>
        )}
      </div>
      <div className="ms-auto">
        <Pagination page={page} numberOfPages={numberOfPages} setPage={setPage} />
      </div>
    </Stack>
  );

  return (
    <div className="AlertEvents">
      <PageSubHeader description="Select an alert above to show the associated events">
        <>Alert</>
        <>Event log</>
      </PageSubHeader>
      <TableCard footer={footer} responsive className="align-middle">
        <thead>
          <tr>
            <th></th>
            <th style={{ minWidth: 120 }}>First occurrence</th>
            <th style={{ minWidth: 110 }}>Status</th>
            <th style={{ minWidth: 220 }}>Description</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {eventRows}
          {!eventRows && (
            <Placeholder as="tr" animation="glow">
              <td width={40}></td>
              <td width={230}>
                <Placeholder xs={9} />
              </td>
              <td width={160}>
                <Placeholder xs={7} bg="danger" />
              </td>
              <td>
                <Placeholder xs={8} />
              </td>
              <td></td>
            </Placeholder>
          )}
        </tbody>
      </TableCard>
    </div>
  );
}

function Alerts() {
  const { alertId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { apps, activeTeam, refreshAlertCounts, backendClient, isTeamAdmin, isDemo } = useGlobal();
  const [selectedAlert, setSelectedAlert] = useState<ListAlertsResponseItem>();
  const [detailsModalAlert, setDetailsModalAlert] = useState<ListAlertsResponseItem>();
  const [appId, setAppId] = useState<number | undefined>(parseInt(searchParams.get("app") || "0", 10) || undefined);
  const [showArchived, setShowArchived] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const isLoading = useIsFetching() > 0;
  const isTeamAdminOrDemo = isTeamAdmin || isDemo;

  useEffect(() => {
    document.title = `Alerts - Apitally`;
  }, []);

  const queryParams = { teamId: activeTeam?.id || 0 };
  const query = useQuery({
    queryKey: ["alerts", queryParams],
    queryFn: () => backendClient!.alerts.listAlerts(queryParams),
    enabled: !!backendClient && !!activeTeam,
  });
  const filteredAlerts = query.data?.filter(
    (alert) => (!alert.archived || showArchived) && (!appId || alert.app.id === appId),
  );
  const alertAppIds = new Set(query.data?.map((alert) => alert.app.id) || []);
  const alertApps = apps?.filter((app) => alertAppIds.has(app.id));
  const unarchivedResolvedAlerts = filteredAlerts?.filter((alert) => alert.resolved && !alert.archived);
  const archivedAlertsCount =
    query.data?.filter((alert) => alert.archived && (!appId || alert.app.id === appId)).length || 0;
  const app = apps?.find((app) => app.id === appId);

  const refresh = async () => {
    await queryClient.refetchQueries({ type: "active" });
  };

  useEffect(() => {
    refreshAlertCounts();
  }, [query.data]);

  useEffect(() => {
    const appId = parseInt(searchParams.get("app") || "0", 10) || undefined;
    setAppId(appId);
  }, [searchParams]);

  useEffect(() => {
    if (appId?.toString() !== searchParams.get("app")) {
      if (appId) {
        searchParams.set("app", appId.toString());
      } else {
        searchParams.delete("app");
      }
      deselectAlert();
    }
  }, [appId]);

  useEffect(() => {
    setShowArchived(false);
  }, [appId]);

  useEffect(() => {
    if (appId && query.isSuccess && !alertAppIds.has(appId)) {
      setAppId(undefined);
    }
  }, [appId, query.isSuccess]);

  useEffect(() => {
    if (
      alertId &&
      selectedAlert &&
      selectedAlert.id === parseInt(alertId, 10) &&
      query.isSuccess &&
      query.data.includes(selectedAlert)
    ) {
      return;
    }
    const alert = alertId ? query.data?.find((alert) => alert.id === parseInt(alertId, 10)) : undefined;
    setSelectedAlert(alert);
    if (alert && alert.archived) {
      setShowArchived(true);
    }
    if (alertId && query.data && !alert) {
      selectFirstAlert();
    }
  }, [alertId, query.data]);

  useEffect(() => {
    const envSearchParam = searchParams.get("env");
    const timeSearchParam = searchParams.get("time");
    if (!alertId && query.data) {
      if (appId && envSearchParam && timeSearchParam) {
        const alert = query.data?.findLast(
          (alert) =>
            alert.app.id === appId &&
            alert.app_env.slug === envSearchParam &&
            DateTime.fromISO(alert.created_at) >= DateTime.fromISO(timeSearchParam),
        );
        if (alert) {
          searchParams.delete("env");
          searchParams.delete("time");
          selectAlert(alert);
        } else {
          setSearchParams((searchParams) => {
            searchParams.delete("env");
            searchParams.delete("time");
            return searchParams;
          });
        }
      } else {
        selectFirstAlert();
      }
    } else if (alertId && filteredAlerts && !filteredAlerts.some((alert) => alert.id === parseInt(alertId, 10))) {
      deselectAlert();
    }
  }, [alertId, searchParams, appId, query.data, showArchived]);

  const selectFirstAlert = () => {
    if (filteredAlerts && filteredAlerts.length > 0) {
      const alert =
        filteredAlerts.find((alert) => !alert.resolved) ||
        filteredAlerts.find((alert) => !alert.archived) ||
        filteredAlerts[0];
      selectAlert(alert);
    } else {
      deselectAlert();
    }
  };

  const selectAlert = (alert: ListAlertsResponseItem) => {
    if (alert.archived) {
      setShowArchived(true);
    }
    if (!alertId || alert.id !== parseInt(alertId, 10)) {
      navigate({ pathname: `/alerts/${alert.id}`, search: searchParams.toString() });
    }
  };

  const deselectAlert = () => {
    if (alertId) {
      navigate({ pathname: "/alerts", search: searchParams.toString() });
    }
  };

  const updateAlert = async (
    alert: ListAlertsResponseItem,
    { ignored, archived }: { ignored?: boolean; archived?: boolean },
  ) => {
    if (backendClient) {
      const promise = backendClient.alerts.updateAlert({ alertId: alert.id, requestBody: { ignored, archived } });
      toast.promise(promise, {
        pending: "Updating alert...",
        success: "Alert updated!",
        error: "Failed to update alert.",
      });
      await promise;
      if (archived && alert.id === selectedAlert?.id) {
        setShowArchived(true);
      }
      refresh();
    }
  };

  const archiveAllAlerts = async () => {
    if (backendClient && unarchivedResolvedAlerts && unarchivedResolvedAlerts.length > 0) {
      const promises = unarchivedResolvedAlerts.map((alert) =>
        backendClient.alerts.updateAlert({ alertId: alert.id, requestBody: { archived: true } }),
      );
      const allPromises = Promise.all(promises);
      const n = unarchivedResolvedAlerts.length;
      toast.promise(allPromises, {
        pending: `Archiving ${n} alert${n !== 1 ? "s" : ""}...`,
        success: `Archived ${n} alert${n !== 1 ? "s" : ""}!`,
        error: "Failed to archive alerts.",
      });
      await allPromises;
      if (unarchivedResolvedAlerts.some((alert) => alert.id === selectedAlert?.id)) {
        setShowArchived(true);
      }
      refresh();
    }
  };

  const alertRows = filteredAlerts?.map((alert) => {
    const isSelected = selectedAlert?.id === alert.id;
    const createdAt = DateTime.fromISO(alert.created_at);
    const resolvedAt = alert.resolved_at ? DateTime.fromISO(alert.resolved_at) : undefined;
    let status = <></>;
    if (!alert.resolved && alert.ignored) {
      status = (
        <span className="text-secondary">
          <FontAwesomeIcon icon={faBellSlash} fixedWidth className="me-1 d-none d-sm-inline" />
          Ignored
        </span>
      );
    } else if (!alert.resolved) {
      status = (
        <span className="text-danger">
          <FontAwesomeIcon icon={faTriangleExclamation} fixedWidth className="me-1 d-none d-sm-inline" />
          Active
        </span>
      );
    } else if (!alert.archived) {
      status = (
        <span className="text-primary">
          <FontAwesomeIcon icon={faCheck} fixedWidth className="me-1 d-none d-sm-inline" />
          Resolved
        </span>
      );
    } else {
      status = (
        <span className="text-secondary">
          <FontAwesomeIcon icon={faBoxArchive} fixedWidth className="me-1 d-none d-sm-inline" />
          Archived
        </span>
      );
    }
    const envBadge = <AppEnvBadge env={alert.app_env} className="me-1" />;

    return (
      <tr
        key={alert.id}
        className={classNames("cursor-pointer", { selected: isSelected })}
        onClick={() => selectAlert(alert)}
      >
        <td
          width={40}
          className={classNames("ps-4", {
            "text-secondary": alert.resolved || alert.ignored,
            "text-danger": !alert.resolved && !alert.ignored,
          })}
        >
          <FontAwesomeIcon icon={isSelected ? faCircleDot : faCircle} />
        </td>
        <td>
          <div>
            {alert.name}
            <span className="d-inline d-lg-none">
              {" "}
              for <span className="d-inline-block">{alert.app.name}</span>
            </span>
          </div>
          <div className="small text-muted">
            <span className="d-inline d-lg-none">
              {status}
              <span className="mx-1" style={{ opacity: 0.5 }}>
                /
              </span>
            </span>
            Triggered {formatRelativeDateTime(createdAt, true)}
            {resolvedAt && (
              <span className="d-none d-lg-inline">
                <span className="mx-1" style={{ opacity: 0.5 }}>
                  /
                </span>
                Active for{" "}
                <span title={resolvedAt.toLocaleString(DateTime.DATETIME_FULL)} className="text-nowrap">
                  {formatDateTimeDiff(createdAt, resolvedAt, true)}
                </span>
              </span>
            )}
          </div>
          <div className="d-block d-sm-none mt-1">{envBadge}</div>
        </td>
        <td className="d-none d-lg-table-cell">{alert.app.name}</td>
        <td className="d-none d-sm-table-cell">{envBadge}</td>
        <td className="d-none d-lg-table-cell">{status}</td>
        <TableCellDropdown>
          <Dropdown.Item as="button" onClick={() => setDetailsModalAlert(alert)}>
            <FontAwesomeIcon icon={faEye} fixedWidth className="text-secondary" />
            Alert details
          </Dropdown.Item>
          {isTeamAdminOrDemo && (
            <>
              <Dropdown.Divider />
              {!alert.ignored && !alert.resolved && !alert.archived && (
                <Dropdown.Item
                  as="button"
                  disabled={!isTeamAdmin}
                  onClick={() => updateAlert(alert, { ignored: true })}
                >
                  <FontAwesomeIcon icon={faBellSlash} fixedWidth className="text-secondary" />
                  Ignore alert
                </Dropdown.Item>
              )}
              {alert.ignored && !alert.resolved && !alert.archived && (
                <Dropdown.Item
                  as="button"
                  disabled={!isTeamAdmin}
                  onClick={() => updateAlert(alert, { ignored: false })}
                >
                  <FontAwesomeIcon icon={faBell} fixedWidth className="text-secondary" />
                  Unignore alert
                </Dropdown.Item>
              )}
              {!alert.archived && (
                <Dropdown.Item
                  as="button"
                  disabled={!isTeamAdmin || !alert.resolved}
                  onClick={() => updateAlert(alert, { archived: true })}
                >
                  <FontAwesomeIcon icon={faBoxArchive} fixedWidth className="text-secondary" />
                  Archive alert
                </Dropdown.Item>
              )}
              {alert.archived && (
                <Dropdown.Item
                  as="button"
                  disabled={!isTeamAdmin}
                  onClick={() => updateAlert(alert, { archived: false })}
                >
                  <FontAwesomeIcon icon={faRotateLeft} fixedWidth className="text-secondary" />
                  Unarchive alert
                </Dropdown.Item>
              )}
            </>
          )}
        </TableCellDropdown>
      </tr>
    );
  });

  const tableFooterButtons = [];
  if (archivedAlertsCount > 0 && !showArchived) {
    tableFooterButtons.push(
      <Button key="show-archived" variant="link" className="me-4" onClick={() => setShowArchived(true)}>
        <FontAwesomeIcon icon={faEye} className="me-icon" />
        Show {archivedAlertsCount} archived alert
        {archivedAlertsCount > 1 ? "s" : ""}
      </Button>,
    );
  } else if (showArchived) {
    tableFooterButtons.push(
      <Button key="hide-archived" variant="link" className="me-4" onClick={() => setShowArchived(false)}>
        <FontAwesomeIcon icon={faEyeSlash} className="me-icon" />
        Hide {archivedAlertsCount} archived alert
        {archivedAlertsCount > 1 ? "s" : ""}
      </Button>,
    );
  }
  if (unarchivedResolvedAlerts && unarchivedResolvedAlerts.length > 0 && isTeamAdminOrDemo) {
    tableFooterButtons.push(
      <Button
        key="archive-all"
        variant="link"
        disabled={!isTeamAdmin}
        className="me-4"
        onClick={() => archiveAllAlerts()}
      >
        <FontAwesomeIcon icon={faBoxArchive} className="me-icon" />
        Archive resolved alerts
      </Button>,
    );
  }
  const tableFooter =
    tableFooterButtons.length > 0 ? <div className="small footer-actions">{tableFooterButtons}</div> : undefined;

  const pageHeaderButtons = [
    <CustomDropdown
      key="app-dropdown"
      title={app?.name || "All apps"}
      icon="/icons/filter-regular.svg"
      align="end"
      className="ms-auto"
      onSelect={(appId: any) => appId !== null && setAppId(parseInt(appId, 10) || undefined)}
    >
      <Dropdown.Menu>
        <Dropdown.Item key={0} eventKey={0} active={!appId}>
          All apps
        </Dropdown.Item>
        {alertApps && alertApps.length > 0 && (
          <>
            <Dropdown.Divider />
            <Dropdown.Header>Filter by app</Dropdown.Header>
            <div style={{ maxHeight: "200px", overflowY: "auto" }}>
              {alertApps.map((app) => (
                <Dropdown.Item key={app.id} eventKey={app.id} active={app.id === appId}>
                  {app.name}
                </Dropdown.Item>
              ))}
            </div>
          </>
        )}
      </Dropdown.Menu>
    </CustomDropdown>,
    <RefreshButton key="refresh-button" onClick={refresh} loading={isLoading} />,
  ];
  const pageHeaderDropdown = (
    <Dropdown.Menu>
      {appId && (
        <Dropdown.Item as="button" onClick={() => setAppId(undefined)}>
          <FontAwesomeIcon icon={faFilterCircleXmark} fixedWidth />
          Clear filter
        </Dropdown.Item>
      )}
      <Dropdown.Item as="button" disabled={isLoading} onClick={refresh}>
        <FontAwesomeIcon icon={faRotateRight} fixedWidth />
        Refresh
      </Dropdown.Item>
    </Dropdown.Menu>
  );

  return (
    <MainLayout>
      <div className="Alerts">
        <AlertDetailsModal alert={detailsModalAlert} setAlert={setDetailsModalAlert} />
        <PageHeader breakpoint="md" buttons={pageHeaderButtons} dropdownMenu={pageHeaderDropdown}>
          Alerts
        </PageHeader>
        {(!alertRows || alertRows.length > 0) && (
          <>
            {appId && (
              <div className="small d-md-none mb-4">
                <FilterBadge
                  icon="/icons/filter-regular.svg"
                  label="App"
                  value={apps?.find((app) => app.id === appId)?.name}
                  removeFilter={() => setAppId(undefined)}
                />
              </div>
            )}
            <TableCard footer={tableFooter} className="mb-6">
              <thead>
                <tr>
                  <th></th>
                  <th>Alert name</th>
                  <th className="d-none d-lg-table-cell">App</th>
                  <th className="d-none d-sm-table-cell">Environment</th>
                  <th className="d-none d-lg-table-cell">Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {alertRows}
                {!alertRows && (
                  <Placeholder as="tr" animation="glow">
                    <td width={40}></td>
                    <td>
                      <div>
                        <Placeholder xs={6} />
                      </div>
                      <div className="small text-muted">
                        <Placeholder xs={8} />
                      </div>
                    </td>
                    <td>
                      <Placeholder xs={8} />
                    </td>
                    <td className="d-none d-sm-table-cell">
                      <Placeholder xs={4} bg="primary" />
                    </td>
                    <td className="d-none d-md-table-cell">
                      <Placeholder xs={8} bg="danger" />
                    </td>
                    <td></td>
                  </Placeholder>
                )}
              </tbody>
            </TableCard>
            {selectedAlert && <AlertEvents alert={selectedAlert} />}
          </>
        )}
        {alertRows && alertRows.length === 0 && (
          <>
            <Card className="bt">
              <Card.Body className="text-center py-6">
                <h2>
                  There are currently no alerts for{" "}
                  <span className="d-inline-block">{app ? app.name : "your apps"}</span>.
                </h2>
                <div>
                  If we detect problems with your app{!app ? "s" : ""} we'll alert you via email and provide all the
                  details on this page.
                </div>
                {archivedAlertsCount > 0 && (
                  <div className="mt-6">
                    <Button variant="secondary" onClick={() => setShowArchived(true)}>
                      Show {archivedAlertsCount} archived alert{archivedAlertsCount > 1 ? "s" : ""}
                    </Button>
                  </div>
                )}
              </Card.Body>
            </Card>
          </>
        )}
      </div>
    </MainLayout>
  );
}

export default Alerts;
