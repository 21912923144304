import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { faSpinner, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { ApiError } from "./backend";
import { useGlobal } from "./contexts/GlobalContext";

function AcceptInvite() {
  const { code } = useParams();
  const { backendClient, teams, refreshTeams, setActiveTeam } = useGlobal();
  const [joinedTeamId, setJoinedTeamId] = useState<number>();
  const [error, setError] = useState<string>();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Accept invite - Apitally";
  }, []);

  useEffect(() => {
    setJoinedTeamId(undefined);
    setError(undefined);
  }, [code]);

  useEffect(() => {
    // Wait until teams are loaded before accepting invite
    if (code && backendClient && teams) {
      const acceptInvite = async () => {
        try {
          const teamId = await backendClient.teams.acceptInvite({ code });
          setJoinedTeamId(teamId);
          refreshTeams();
          toast.success("Invite accepted!");
        } catch (e) {
          setError(e instanceof ApiError ? (e.body as any).detail : "Failed to accept invite.");
        }
      };
      if (!joinedTeamId && !error) {
        acceptInvite();
      }
    }
  }, [code, backendClient, teams]);

  useEffect(() => {
    if (joinedTeamId && teams) {
      const team = teams.find((team) => team.id === joinedTeamId);
      if (team) {
        setActiveTeam(team);
        navigate("/apps");
      }
    }
  }, [joinedTeamId, teams]);

  return (
    <Modal show className="AcceptInviteModal">
      {!error ? (
        <Modal.Header style={{ borderRadius: "var(--bs-modal-inner-border-radius)" }}>
          <Modal.Title className="text-center w-100">
            <div className="pt-4 pb-2">
              <FontAwesomeIcon
                icon={!joinedTeamId ? faSpinner : faCircleCheck}
                spinPulse={!joinedTeamId}
                className="fs-1 text-primary me-2"
              />
            </div>
            <div className="pb-2">Accepting team invite...</div>
          </Modal.Title>
        </Modal.Header>
      ) : (
        <>
          <Modal.Header>
            <Modal.Title className="text-center w-100">
              <div className="pt-4 pb-2">
                <FontAwesomeIcon icon={faTriangleExclamation} className="fs-1 text-danger me-2" />
              </div>
              <div className="pb-2">Unable to accept invite</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant="danger" className="mb-0">
              {error}
            </Alert>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => navigate("/apps")}>
              Cancel
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
}

export default AcceptInvite;
