import React, { CSSProperties } from "react";

import "./TableCellWithBar.css";

type TableCellWithBarProps = {
  showBar?: boolean;
  value?: number | null;
  maxValue?: number;
  children?: React.ReactNode;
};

function TableCellWithBar({ showBar = true, value, maxValue, children }: TableCellWithBarProps) {
  return (
    <td
      className="TableCellBar"
      style={
        {
          "--bar-width": showBar && value && maxValue ? `${(value / maxValue) * 0.8 * 100}%` : "0%",
        } as CSSProperties
      }
    >
      <div>
        <span>{children}</span>
      </div>
    </td>
  );
}

export default TableCellWithBar;
