import {
  faCheckCircle,
  faCircleDown,
  faCircleUp,
  faFaceAngry,
  faFaceFrownOpen,
  faFaceGrinStars,
  faFaceMeh,
  faFaceSmile,
  faXmarkCircle,
} from "@fortawesome/free-regular-svg-icons";
import {
  faAngleRight,
  faArrowRightArrowLeft,
  faBug,
  faChartSimple,
  faInfo,
  faStopwatch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import React, { useCallback, useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Markdown from "react-markdown";
import { useNavigate } from "react-router-dom";

import { AppEnvItem, GetEndpointResponse, GetTrafficMetricsResponse, ListAppsResponseItem } from "../backend";
import DataTransferredBarChart from "../charts/DataTransferredBarChart";
import ErrorsBarChart from "../charts/ErrorsBarChart";
import PayloadSizeHistogram from "../charts/PayloadSizeHistogram";
import RequestsBarChart from "../charts/RequestsBarChart";
import RequestsByConsumerBarChart from "../charts/RequestsByConsumerBarChart";
import ResponseTimeHistogram from "../charts/ResponseTimeHistogram";
import ResponseTimeLineChart from "../charts/ResponseTimeLineChart";
import ServerErrorsTable from "../charts/ServerErrorsTable";
import ValidationErrorsTable from "../charts/ValidationErrorsTable";
import MethodPath from "../components/MethodPath";
import MetricChip from "../components/MetricChip";
import ModalSection from "../components/ModalSection";
import { useGlobal } from "../contexts/GlobalContext";
import { Endpoint } from "../types/Endpoint";
import { checkClientVersion } from "../utils/clientVersion";
import { formatDataSize, formatResponseTime } from "../utils/numbers";
import { formatPeriod, isCustomPeriod } from "../utils/period";
import "./EndpointModal.scss";

type EndpointModalTabProps = {
  app: ListAppsResponseItem;
  env?: AppEnvItem;
  endpoint: Endpoint;
  period: string;
  consumerId?: number;
  consumerGroupId?: number;
  metrics?: GetTrafficMetricsResponse;
  selectTab?: (key: string) => void;
};

function EndpointModalInfoTab({ endpointInfo }: { endpointInfo: GetEndpointResponse }) {
  if (!endpointInfo.summary && !endpointInfo.description) {
    return <></>;
  }
  return (
    <>
      {endpointInfo.summary && (
        <ModalSection title="Summary">
          <div className="border rounded px-4 py-2">{endpointInfo.summary}</div>
        </ModalSection>
      )}
      {endpointInfo.description && (
        <ModalSection title="Description" className={endpointInfo.summary ? "mt-6" : undefined}>
          <div className="border rounded px-4 py-2">
            <Markdown>{endpointInfo.description}</Markdown>
          </div>
        </ModalSection>
      )}
    </>
  );
}

function EndpointModalRequestsTab({
  app,
  env,
  endpoint,
  period,
  consumerId,
  consumerGroupId,
  metrics,
  selectTab,
}: EndpointModalTabProps) {
  const successfulRequestCount = metrics
    ? metrics.total_request_count - metrics.client_error_count - metrics.server_error_count
    : undefined;
  const clientErrorRate =
    metrics && metrics.client_error_count > 0 ? metrics.client_error_count / metrics.total_request_count : undefined;
  const serverErrorRate =
    metrics && metrics.server_error_count > 0 ? metrics.server_error_count / metrics.total_request_count : undefined;
  return (
    <>
      {endpoint.excluded && (
        <Alert variant="danger">
          <span className="fw-bold">This endpoint is marked as excluded.</span>
          <br />
          Requests to this endpoint are excluded from traffic metric calculations and charts (except in this modal).
        </Alert>
      )}
      <ModalSection
        title="Summary"
        titleExtra={!isCustomPeriod(period) ? `last ${formatPeriod(period)}` : formatPeriod(period)}
      >
        <div className="mb-n4 me-n4">
          <MetricChip
            label="Total requests"
            icon="/icons/hashtag-regular.svg"
            value={metrics?.total_request_count.toLocaleString() || "-"}
          />
          <MetricChip
            label="Requests per minute"
            icon="/icons/gauge-regular.svg"
            value={metrics?.requests_per_minute.toLocaleString(undefined, { maximumFractionDigits: 2 }) || "-"}
          />
          <MetricChip
            label="Successful requests"
            icon={faCheckCircle}
            iconClassName="text-primary"
            value={successfulRequestCount?.toLocaleString() || "-"}
          />
          <MetricChip
            label="Client errors"
            icon={faXmarkCircle}
            iconClassName="text-danger"
            value={metrics?.client_error_count.toLocaleString() || "-"}
            extra={clientErrorRate
              ?.toLocaleString(undefined, { style: "percent", maximumFractionDigits: 1 })
              .replace("%", " %")}
            onClick={selectTab && metrics?.client_error_count ? () => selectTab("errors") : undefined}
          />
          <MetricChip
            label="Server errors"
            icon="/icons/bug-regular.svg"
            iconClassName="text-danger"
            value={metrics?.server_error_count.toLocaleString() || "-"}
            extra={serverErrorRate
              ?.toLocaleString(undefined, { style: "percent", maximumFractionDigits: 1 })
              .replace("%", " %")
              .replace(/^0 %$/, "")}
            onClick={selectTab && metrics?.server_error_count ? () => selectTab("errors") : undefined}
          />
        </div>
      </ModalSection>
      <ModalSection title="Number of requests over time" className="mt-6">
        <div className="border rounded p-4">
          <RequestsBarChart
            app={app}
            env={env}
            consumerId={consumerId}
            consumerGroupId={consumerGroupId}
            period={period}
            endpoint={endpoint}
            displayTitle={false}
          />
        </div>
      </ModalSection>
      {!consumerId && (
        <ModalSection title="Number of requests per consumer" titleExtra="top 10" className="mt-6">
          <div className="border rounded p-4">
            <RequestsByConsumerBarChart
              app={app}
              env={env}
              consumerGroupId={consumerGroupId}
              period={period}
              endpoint={endpoint}
            />
          </div>
        </ModalSection>
      )}
    </>
  );
}

function EndpointModalErrorsTab({ app, env, endpoint, period, consumerId, consumerGroupId }: EndpointModalTabProps) {
  const { backendClient, timezone } = useGlobal();
  const navigate = useNavigate();

  const statusCodeCountsQueryParams = {
    appId: app.id,
    appEnv: env?.slug,
    method: endpoint.method,
    path: endpoint.path,
    consumerId,
    consumerGroupId,
    period,
    timezone,
  };
  const statusCodeCountsQuery = useQuery({
    queryKey: ["statusCodeCounts", statusCodeCountsQueryParams],
    queryFn: () => backendClient!.traffic.getStatusCodeCounts(statusCodeCountsQueryParams),
    enabled: !!backendClient,
  });
  const totalRequestCount = statusCodeCountsQuery.data?.reduce((acc, item) => acc + item.request_count, 0) || 0;
  const errorStatusCodeCounts = statusCodeCountsQuery.data?.filter((item) => item.status_code >= 400);

  return (
    <>
      <ModalSection
        title="Failed requests by status code"
        titleExtra={!isCustomPeriod(period) ? `last ${formatPeriod(period)}` : formatPeriod(period)}
      >
        {errorStatusCodeCounts && errorStatusCodeCounts.length > 0 && (
          <div className="mb-n4 me-n4">
            {errorStatusCodeCounts.map((item) => (
              <MetricChip
                key={item.status_code}
                label={`${item.status_code} ${item.description || "Unknown Status"}`}
                icon={item.expected ? faCheckCircle : item.status_code >= 500 ? faBug : faXmarkCircle}
                iconClassName={item.expected ? "text-primary" : "text-danger"}
                value={item.request_count.toLocaleString()}
                extra={(item.request_count / totalRequestCount)
                  .toLocaleString(undefined, { style: "percent", maximumFractionDigits: 1 })
                  .replace("%", " %")
                  .replace(/^0 %$/, "")}
                onClick={() =>
                  navigate(
                    `/errors/${app.slug}?period=${period}&${env ? `env=${env.slug}&` : ""}method=${endpoint.method}&path=${encodeURIComponent(endpoint.path)}&status_code=${item.status_code}`,
                  )
                }
                className={item.expected ? "expected" : undefined}
                tooltip={item.expected ? "Counts as successful because it has been marked as expected." : undefined}
              />
            ))}
          </div>
        )}
        {errorStatusCodeCounts && errorStatusCodeCounts.length === 0 && (
          <div className="border rounded px-4 py-6 text-center">No failed requests in the selected period.</div>
        )}
      </ModalSection>
      <ModalSection title="Number of client & server errors over time" className="mt-6">
        <div className="border rounded p-4">
          <ErrorsBarChart
            app={app}
            env={env}
            consumerId={consumerId}
            consumerGroupId={consumerGroupId}
            period={period}
            endpoint={endpoint}
            displayTitle={false}
          />
        </div>
      </ModalSection>
      <ModalSection title="Validation errors" titleExtra="top 10" className="mt-6">
        <div className="px-4">
          <ValidationErrorsTable
            app={app}
            env={env}
            consumerId={consumerId}
            consumerGroupId={consumerGroupId}
            period={period}
            endpoint={endpoint}
            limit={10}
          />
        </div>
      </ModalSection>
      <ModalSection title="Unhandled exceptions" titleExtra="top 10" className="mt-6">
        <div className="px-4">
          <ServerErrorsTable
            app={app}
            env={env}
            consumerId={consumerId}
            consumerGroupId={consumerGroupId}
            period={period}
            endpoint={endpoint}
            limit={10}
          />
        </div>
      </ModalSection>
    </>
  );
}

function EndpointModalResponseTimesTab({
  app,
  env,
  endpoint,
  period,
  consumerId,
  consumerGroupId,
}: EndpointModalTabProps) {
  const { backendClient, timezone } = useGlobal();

  const metricsQueryParams = {
    appId: app.id,
    appEnv: env?.slug,
    method: endpoint.method,
    path: endpoint.path,
    consumerId,
    consumerGroupId,
    period,
    timezone,
  };
  const metricsQuery = useQuery({
    queryKey: ["performanceMetrics", metricsQueryParams],
    queryFn: () => backendClient!.performance.getPerformanceMetrics(metricsQueryParams),
    enabled: !!backendClient,
    placeholderData: undefined,
  });

  let apdexScoreIcon = faFaceSmile;
  if (metricsQuery.isSuccess && metricsQuery.data.total_request_count > 0) {
    if (metricsQuery.data.apdex_score >= 0.93) {
      apdexScoreIcon = faFaceGrinStars;
    } else if (metricsQuery.data.apdex_score >= 0.85) {
      apdexScoreIcon = faFaceSmile;
    } else if (metricsQuery.data.apdex_score >= 0.7) {
      apdexScoreIcon = faFaceMeh;
    } else if (metricsQuery.data.apdex_score >= 0.5) {
      apdexScoreIcon = faFaceFrownOpen;
    } else {
      apdexScoreIcon = faFaceAngry;
    }
  }

  return (
    <>
      <ModalSection
        title="Summary"
        titleExtra={!isCustomPeriod(period) ? `last ${formatPeriod(period)}` : formatPeriod(period)}
      >
        <div className="mb-n4 me-n4">
          <MetricChip
            label="Apdex score"
            icon={apdexScoreIcon}
            value={
              metricsQuery.isSuccess && metricsQuery.data.total_request_count > 0
                ? metricsQuery.data.apdex_score.toLocaleString(undefined, {
                    minimumFractionDigits: 3,
                    maximumFractionDigits: 3,
                  })
                : "-"
            }
          />
          <MetricChip
            label="50th percentile"
            icon="/icons/stopwatch-regular.svg"
            value={
              metricsQuery.isSuccess && metricsQuery.data.total_request_count > 0
                ? formatResponseTime(metricsQuery.data.response_time_p50)
                : "-"
            }
          />
          <MetricChip
            label="75th percentile"
            icon="/icons/stopwatch-regular.svg"
            value={
              metricsQuery.isSuccess && metricsQuery.data.total_request_count > 0
                ? formatResponseTime(metricsQuery.data.response_time_p75)
                : "-"
            }
          />
          <MetricChip
            label="95th percentile"
            icon="/icons/stopwatch-regular.svg"
            value={
              metricsQuery.isSuccess && metricsQuery.data.total_request_count > 0
                ? formatResponseTime(metricsQuery.data.response_time_p95)
                : "-"
            }
          />
        </div>
      </ModalSection>
      <ModalSection title="Response times over time" className="mt-6">
        <div className="border rounded p-4">
          <ResponseTimeLineChart
            app={app}
            env={env}
            consumerId={consumerId}
            consumerGroupId={consumerGroupId}
            period={period}
            endpoint={endpoint}
            displayTitle={false}
          />
        </div>
      </ModalSection>
      <ModalSection title="Histogram of response times" className="mt-6">
        <div className="border rounded p-4">
          <ResponseTimeHistogram app={app} env={env} consumerId={consumerId} period={period} endpoint={endpoint} />
        </div>
      </ModalSection>
    </>
  );
}

function EndpointModalPayloadSizesTab({
  app,
  env,
  endpoint,
  period,
  consumerId,
  consumerGroupId,
  metrics,
}: EndpointModalTabProps) {
  const showRequestSize = !["get", "head", "delete"].includes(endpoint.method.toLowerCase());
  return (
    <>
      <ModalSection
        title="Summary"
        titleExtra={!isCustomPeriod(period) ? `last ${formatPeriod(period)}` : formatPeriod(period)}
      >
        <div className="mb-n4 me-n4">
          <MetricChip
            label="Total data transferred"
            icon="/icons/arrow-right-arrow-left-regular.svg"
            value={metrics ? formatDataSize(metrics.total_data_transferred) : "-"}
          />
          {showRequestSize && (
            <MetricChip
              label="Average request size"
              icon={faCircleDown}
              value={
                metrics && metrics.request_size_avg != null && metrics.total_request_count > 0
                  ? formatDataSize(metrics.request_size_avg)
                  : "-"
              }
            />
          )}
          <MetricChip
            label="Average response size"
            icon={faCircleUp}
            value={
              metrics && metrics.response_size_avg != null && metrics.total_request_count > 0
                ? formatDataSize(metrics.response_size_avg)
                : "-"
            }
          />
        </div>
      </ModalSection>
      <ModalSection title="Data transferred over time" className="mt-6">
        <div className="border rounded p-4">
          <DataTransferredBarChart
            app={app}
            env={env}
            consumerId={consumerId}
            consumerGroupId={consumerGroupId}
            period={period}
            endpoint={endpoint}
            displayTitle={false}
          />
        </div>
      </ModalSection>
      {showRequestSize && (
        <ModalSection title="Histogram of request sizes" className="mt-6">
          <div className="border rounded p-4">
            <PayloadSizeHistogram
              payloadType="request"
              app={app}
              env={env}
              consumerId={consumerId}
              consumerGroupId={consumerGroupId}
              period={period}
              endpoint={endpoint}
            />
          </div>
        </ModalSection>
      )}
      <ModalSection title="Histogram of response sizes" className="mt-6">
        <div className="border rounded p-4">
          <PayloadSizeHistogram
            payloadType="response"
            app={app}
            env={env}
            consumerId={consumerId}
            consumerGroupId={consumerGroupId}
            period={period}
            endpoint={endpoint}
          />
        </div>
      </ModalSection>
    </>
  );
}

type EndpointModalProps = {
  app: ListAppsResponseItem;
  env?: AppEnvItem;
  consumerId?: number;
  consumerGroupId?: number;
  period: string;
  endpoint?: Endpoint;
  setEndpoint: React.Dispatch<React.SetStateAction<Endpoint | undefined>>;
  initialTab?: string;
};

function EndpointModal({
  app,
  env,
  consumerId,
  consumerGroupId,
  period,
  endpoint,
  setEndpoint,
  initialTab = "requests",
}: EndpointModalProps) {
  const { backendClient, isDemo, timezone } = useGlobal();
  const [activeTab, setActiveTab] = useState(initialTab);

  useEffect(() => {
    setActiveTab(initialTab);
  }, [endpoint]);

  const selectTab = useCallback((key: string | null) => {
    if (key) {
      setActiveTab(key);
    }
  }, []);

  const metricsQueryParams = {
    appId: app.id,
    appEnv: env?.slug,
    consumerId,
    consumerGroupId,
    method: endpoint?.method,
    path: endpoint?.path,
    period,
    timezone,
  };
  const metricsQuery = useQuery({
    queryKey: ["trafficMetrics", metricsQueryParams],
    queryFn: () => backendClient!.traffic.getTrafficMetrics(metricsQueryParams),
    enabled: !!backendClient && !!endpoint,
    placeholderData: undefined,
  });
  const endpointQueryParams = { appId: app.id, endpointId: endpoint?.id || 0 };
  const endpointQuery = useQuery({
    queryKey: ["endpoint", endpointQueryParams],
    queryFn: () => backendClient!.endpoints.getEndpoint(endpointQueryParams),
    enabled: !!backendClient && !!endpoint?.id,
  });
  const showUpdateClientAlert =
    !metricsQuery.data?.total_data_transferred &&
    !isDemo &&
    !checkClientVersion({ app, env, python: "0.4.0", js: "0.2.0" });

  if (!endpoint) {
    return <></>;
  }

  const tabs = [
    {
      eventKey: "info",
      title: "Info",
      icon: faInfo,
      content: endpointQuery?.data && (!!endpointQuery.data.summary || !!endpointQuery.data.description) && (
        <EndpointModalInfoTab endpointInfo={endpointQuery.data} />
      ),
    },
    {
      eventKey: "requests",
      title: "Requests",
      icon: faChartSimple,
      content: (
        <EndpointModalRequestsTab
          app={app}
          env={env}
          consumerId={consumerId}
          consumerGroupId={consumerGroupId}
          period={period}
          endpoint={endpoint}
          metrics={metricsQuery.data}
          selectTab={selectTab}
        />
      ),
    },
    {
      eventKey: "errors",
      title: "Errors",
      icon: faBug,
      content: (
        <EndpointModalErrorsTab
          app={app}
          env={env}
          consumerId={consumerId}
          consumerGroupId={consumerGroupId}
          period={period}
          endpoint={endpoint}
        />
      ),
    },
    {
      eventKey: "response-times",
      title: "Response times",
      icon: faStopwatch,
      content: (
        <EndpointModalResponseTimesTab
          app={app}
          env={env}
          consumerId={consumerId}
          consumerGroupId={consumerGroupId}
          period={period}
          endpoint={endpoint}
          metrics={metricsQuery.data}
        />
      ),
    },
    {
      eventKey: "data-transferred",
      title: "Data transferred",
      icon: faArrowRightArrowLeft,
      content: showUpdateClientAlert ? (
        <Alert variant="danger" className="m-0">
          Please update the Apitally client library in your project to capture request and response payload sizes.
        </Alert>
      ) : (
        <EndpointModalPayloadSizesTab
          app={app}
          env={env}
          consumerId={consumerId}
          consumerGroupId={consumerGroupId}
          period={period}
          endpoint={endpoint}
          metrics={metricsQuery.data}
        />
      ),
    },
  ].filter((tab) => tab.content);

  return (
    <Modal className="EndpointModal" size="xl" show={!!endpoint} onHide={() => setEndpoint(undefined)}>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="app-env-name">
            {app.name}
            {env && (
              <>
                <FontAwesomeIcon icon={faAngleRight} className="ms-2 me-2 text-very-muted small" />
                {env.name}
              </>
            )}
          </div>
          <div className="method-path">
            <MethodPath method={endpoint.method} path={endpoint.path} />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-1 px-0">
        <Tab.Container activeKey={activeTab} onSelect={selectTab}>
          <Nav variant="tabs">
            {tabs.map((tab) => (
              <Nav.Item key={tab.eventKey} title={tab.title}>
                <Nav.Link eventKey={tab.eventKey}>
                  <FontAwesomeIcon icon={tab.icon} fixedWidth className="d-inline d-lg-none" />
                  <span className="d-none d-lg-inline">{tab.title}</span>
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          <Tab.Content>
            {tabs.map((tab) => (
              <Tab.Pane key={tab.eventKey} eventKey={tab.eventKey}>
                {tab.content}
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Tab.Container>
      </Modal.Body>
    </Modal>
  );
}

export default EndpointModal;
