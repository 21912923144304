import Badge from "react-bootstrap/Badge";

import "./MethodBadge.css";

type MethodBadgeProps = {
  method: string;
};

function MethodBadge({ method }: MethodBadgeProps): JSX.Element {
  return (
    <Badge className={`MethodBadge ${method.toLowerCase()}`} title={method.toUpperCase()}>
      {method.toUpperCase()}
    </Badge>
  );
}

export default MethodBadge;
