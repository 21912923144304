import { ActiveElement, Chart, ChartEvent, ChartOptions, TooltipItem } from "chart.js";
import { DateTime } from "luxon";
import { formatPeriodStep } from "./period";

type TimeScale = {
  step: number;
  range: number;
};

export function getTimeScale(labels: string[]): TimeScale {
  if (!labels || labels.length < 2 || typeof labels[0] !== "string" || typeof labels[1] !== "string") {
    return { step: 0, range: 0 };
  }
  const first = DateTime.fromISO(labels[0]);
  const second = DateTime.fromISO(labels[1]);
  const last = DateTime.fromISO(labels.at(-1)!);
  return { step: second.diff(first).as("milliseconds"), range: last.diff(first).as("milliseconds") };
}

function getTimeScaleUnit(timeScale: TimeScale) {
  const stepHours = timeScale.step / 1000 / 60 / 60;
  const rangeDays = timeScale.range / 1000 / 60 / 60 / 24;
  if (stepHours <= 6) {
    if (rangeDays < 28) {
      return "hour" as const;
    } else {
      return "day" as const;
    }
  } else if (stepHours < 24 * 7) {
    return "day" as const;
  } else if (stepHours <= 24 * 28) {
    return "week" as const;
  } else if (stepHours < 24 * 30 * 12) {
    return "month" as const;
  } else {
    return "year" as const;
  }
}

type GetChartOptionsOptions = {
  labels: string[];
  title?: string;
  tooltipUnit?: string;
  setPeriod?: (period: string) => void;
};

export function getChartOptions({ labels, title, tooltipUnit, setPeriod }: GetChartOptionsOptions) {
  const timeScale = getTimeScale(labels);
  const stepMinutes = timeScale.step / 1000 / 60;
  const stepHours = stepMinutes / 60;
  const rangeHours = timeScale.range / 1000 / 60 / 60;
  const isMonthlyStep = stepHours >= 24 * 28;
  const isHighFreq = labels.length >= 60;
  const lastLabel = labels.at(-1);
  const periodEndWithin31Days = lastLabel && DateTime.fromISO(lastLabel).diffNow().as("days") > -31;
  const timeScaleUnit = getTimeScaleUnit(timeScale);
  const useAmericanDateFormat = navigator.language === "en-US";
  const dayMonthFormat = useAmericanDateFormat ? "MMM d" : "d MMM";
  const allowPeriodSelection = setPeriod && ((periodEndWithin31Days && stepMinutes >= 1) || stepMinutes >= 30);

  const chartOptions: ChartOptions<"bar" | "line"> = {
    responsive: true,
    maintainAspectRatio: false,
    font: {
      family: "DM Sans",
    },
    scales: {
      x: {
        type: "timeseries" as const,
        time: {
          unit: timeScaleUnit,
          displayFormats: {
            day: dayMonthFormat,
            hour: "HH:mm",
          },
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          font: {
            family: "DM Sans",
          },
          color: "#adb5bd",
          maxRotation: 0,
          callback:
            isHighFreq && (timeScaleUnit === "hour" || timeScaleUnit === "day")
              ? function (tickValue: string | number) {
                  const time = DateTime.fromMillis(tickValue as number);
                  let show;
                  if (timeScaleUnit === "hour") {
                    if (rangeHours >= 7 * 24) {
                      show = time.hour % 3 === 0 && time.minute === 0;
                    } else if (rangeHours >= 2 * 24) {
                      show = time.hour % 2 === 0 && time.minute === 0;
                    } else if (rangeHours >= 24) {
                      show = time.minute === 0;
                    } else if (rangeHours > 1) {
                      show = time.minute % 10 === 0;
                    } else {
                      show = time.minute % 5 === 0;
                    }
                    return show ? time.toFormat("HH:mm") : null;
                  } else if (timeScaleUnit === "day") {
                    show = time.hour === 0 && time.minute === 0;
                    return show ? time.toFormat(dayMonthFormat) : null;
                  }
                }
              : Chart.defaults.scales.time.ticks.callback,
        },
      },
      y: {
        grid: {
          display: true,
          color: "#faf9fb",
        },
        border: {
          display: false,
        },
        ticks: {
          font: {
            family: "DM Sans",
          },
          color: "#adb5bd",
        },
        display: true,
        beginAtZero: true,
      },
    },
    animation: {
      duration: 0,
    },
    interaction: {
      mode: "index",
    },
    plugins: {
      title: {
        display: !!title,
        text: title,
        font: {
          family: "DM Sans",
          size: 14,
          weight: "bold" as const,
        },
      },
      tooltip: {
        mode: "index" as const,
        callbacks: {
          title: function (context: TooltipItem<"bar" | "line">[]) {
            const start = DateTime.fromMillis(context[0].parsed.x);
            const end = isMonthlyStep ? start.plus({ months: 1 }) : start.plus(timeScale.step);
            return formatPeriodStep(start, end);
          },
          label: function (context: TooltipItem<"bar" | "line">) {
            const value = context.parsed.y.toLocaleString();
            const unit = tooltipUnit ? " " + tooltipUnit : "";
            return ` ${context.dataset.label}: ${value}${unit}`;
          },
          footer: function (context: TooltipItem<"bar" | "line">[]) {
            if (allowPeriodSelection) {
              const start = DateTime.fromMillis(context[0].parsed.x);
              if ((start.diffNow().as("days") > -31 && stepMinutes >= 1) || stepMinutes >= 30) {
                return "Click to select period";
              }
            }
          },
        },
        footerFont: {
          family: "DM Sans",
          size: 10,
          weight: "normal" as const,
          style: "italic" as const,
        },
        footerColor: "#ccc",
      },
      legend: {
        display: false,
      },
    },
    onHover: (event: ChartEvent, elements: ActiveElement[]) => {
      if (event.native?.target && event.native.target instanceof HTMLElement) {
        event.native.target.style.cursor = elements.length > 0 && allowPeriodSelection ? "pointer" : "default";
      }
    },
    onClick: (event: ChartEvent, elements: ActiveElement[], chart: Chart) => {
      if (event.native && allowPeriodSelection) {
        const res = chart.getElementsAtEventForMode(event.native, "x", { intersect: true }, true);
        if (res.length > 0) {
          const format = "yyyy-MM-dd'T'HH:mm:ss";
          const start = DateTime.fromISO(labels[res[0].index]);
          const end = isMonthlyStep ? start.plus({ months: 1 }) : start.plus(timeScale.step);
          if ((start.diffNow().as("days") > -31 && stepMinutes >= 1) || stepMinutes >= 30) {
            setPeriod(`${start.toFormat(format)}|${end.toFormat(format)}`);
          }
        }
      }
    },
  };

  return chartOptions;
}
