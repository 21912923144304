import { useQuery } from "@tanstack/react-query";
import { Field, FieldProps, Form, Formik } from "formik";
import Button from "react-bootstrap/Button";
import BootstrapForm from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import { AppEnvItem, ListAppsResponseItem } from "../backend";
import Select from "../components/Select";
import { useGlobal } from "../contexts/GlobalContext";

type DashboardFilterModalProps = {
  show: boolean;
  app: ListAppsResponseItem;
  env?: AppEnvItem;
  consumerId?: number;
  consumerGroupId?: number;
  endpointGroupId?: number;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setConsumerId: (consumerId?: number) => void;
  setConsumerGroupId: (consumerGroupId?: number) => void;
  setEndpointGroupId: (endpointGroupId?: number) => void;
};

function DashboardFilterModal({
  show,
  app,
  env,
  consumerId,
  consumerGroupId,
  endpointGroupId,
  setShow,
  setConsumerId,
  setConsumerGroupId,
  setEndpointGroupId,
}: DashboardFilterModalProps) {
  const { backendClient } = useGlobal();

  const consumersQueryParams = { appId: app?.id || 0, appEnv: env?.slug };
  const { data: consumers } = useQuery({
    queryKey: ["consumers", consumersQueryParams],
    queryFn: () => backendClient!.consumers.listConsumers(consumersQueryParams),
    enabled: !!backendClient && show,
  });
  const { data: consumerGroups } = useQuery({
    queryKey: ["consumerGroups", consumersQueryParams],
    queryFn: () => backendClient!.consumers.listConsumerGroups(consumersQueryParams),
    enabled: !!backendClient && show,
  });
  const endpointGroupsQueryParams = { appId: app?.id || 0, appEnvId: env?.id };
  const { data: endpointGroups } = useQuery({
    queryKey: ["endpointGroups", endpointGroupsQueryParams],
    queryFn: () => backendClient!.endpoints.listEndpointGroups(endpointGroupsQueryParams),
    enabled: !!backendClient && show,
  });

  const consumerOptions = consumers?.map((consumer) => ({ label: consumer.name, value: consumer.id })) || [];
  const consumerGroupOptions =
    consumerGroups?.map((consumerGroup) => ({ label: consumerGroup.name, value: consumerGroup.id })) || [];
  const endpointGroupOptions =
    endpointGroups?.map((endpointGroup) => ({ label: endpointGroup.name, value: endpointGroup.id })) || [];

  return (
    <Formik
      initialValues={{
        consumerId: consumerId || 0,
        consumerGroupId: consumerGroupId || 0,
        endpointGroupId: endpointGroupId || 0,
      }}
      enableReinitialize
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          setConsumerId(values.consumerId || undefined);
          setConsumerGroupId(!values.consumerId && values.consumerGroupId ? values.consumerGroupId : undefined);
          setEndpointGroupId(values.endpointGroupId || undefined);
          setShow(false);
          resetForm();
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, isSubmitting, resetForm }) => (
        <Modal show={show} onHide={() => setShow(false)} onExited={() => resetForm()}>
          <Modal.Header closeButton>
            <Modal.Title>Filter dashboard</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <BootstrapForm.Group controlId="formConsumer">
                <BootstrapForm.Label>Consumer</BootstrapForm.Label>
                <Field name="consumerId">
                  {({ field }: FieldProps<number>) => (
                    <Select options={consumerOptions} placeholder="All consumers" isSearchable isClearable {...field} />
                  )}
                </Field>
              </BootstrapForm.Group>
              <BootstrapForm.Group controlId="formConsumerGroup" className="mt-4">
                <BootstrapForm.Label>Consumer group</BootstrapForm.Label>
                <Field name="consumerGroupId">
                  {({ field }: FieldProps<number>) => (
                    <Select
                      options={consumerGroupOptions}
                      placeholder="All consumer groups"
                      isSearchable
                      isClearable
                      {...field}
                    />
                  )}
                </Field>
              </BootstrapForm.Group>
              <BootstrapForm.Group controlId="formEndpointGroup" className="mt-4">
                <BootstrapForm.Label>Endpoint group</BootstrapForm.Label>
                <Field name="endpointGroupId">
                  {({ field }: FieldProps<number>) => (
                    <Select
                      options={endpointGroupOptions}
                      placeholder="All endpoints"
                      isSearchable
                      isClearable
                      {...field}
                    />
                  )}
                </Field>
              </BootstrapForm.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setConsumerId(undefined);
                setConsumerGroupId(undefined);
                setEndpointGroupId(undefined);
                setShow(false);
              }}
            >
              Clear all
            </Button>
            <Button onClick={() => handleSubmit()} disabled={isSubmitting}>
              Apply
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
}

export default DashboardFilterModal;
