import { DateTime } from "luxon";
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { ListConsumersWithTrafficResponseItem } from "../backend";
import DetailsModalContent from "../components/DetailsModalContent";
import ModalHeaderEditButton from "../components/ModalHeaderEditButton";
import { formatRelativeDateTime } from "../utils/datetime";
import { formatPeriod } from "../utils/period";

type ConsumerDetailsModalProps = {
  consumer?: ListConsumersWithTrafficResponseItem;
  setConsumer: React.Dispatch<React.SetStateAction<ListConsumersWithTrafficResponseItem | undefined>>;
  onEditClick: () => void;
  period: string;
};

function ConsumerDetailsModal({ consumer, setConsumer, onEditClick, period }: ConsumerDetailsModalProps) {
  const firstRequestDateTime = consumer?.first_request_at ? DateTime.fromISO(consumer.first_request_at) : undefined;
  const lastRequestDateTime = consumer?.last_request_at ? DateTime.fromISO(consumer.last_request_at) : undefined;

  return (
    <Modal show={consumer !== undefined} onHide={() => setConsumer(undefined)}>
      <Modal.Header closeButton>
        <Modal.Title>Consumer details</Modal.Title>
        <ModalHeaderEditButton onClick={onEditClick} />
      </Modal.Header>
      <Modal.Body>
        <DetailsModalContent
          labelValueMap={{
            "Identifier": <code>{consumer?.identifier}</code>,
            "Name": consumer?.name,
            "Group": consumer?.group?.name,
            "Requests": (
              <>
                {consumer?.requests.toLocaleString()}
                <span className="ms-2 small text-muted">(in last {formatPeriod(period)})</span>
              </>
            ),
            "Error rate":
              consumer?.error_rate
                ?.toLocaleString(undefined, { style: "percent", minimumFractionDigits: 1 })
                .replace("%", " %") || "-",
            "First request": <span>{firstRequestDateTime?.toLocaleString(DateTime.DATETIME_FULL)}</span>,
            "Last request": (
              <span title={lastRequestDateTime?.toLocaleString(DateTime.DATETIME_FULL)}>
                {formatRelativeDateTime(lastRequestDateTime)}
              </span>
            ),
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setConsumer(undefined)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConsumerDetailsModal;
