import { useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

import { useGlobal } from "../contexts/GlobalContext";

function LaunchDemoAlert() {
  const { apps, isDemo, launchDemo } = useGlobal();
  const [dismissed, setDismissed] = useState(localStorage.getItem("launchDemoAlertDismissed") === "1");
  const appsLoaded = apps !== undefined;
  const hasAppsWithData = apps && apps.some((app) => app.envs.some((env) => !!env.last_sync_at));
  const show = !dismissed && appsLoaded && !hasAppsWithData;

  const handleClose = () => {
    setDismissed(true);
    localStorage.setItem("launchDemoAlertDismissed", "1");
  };

  if (isDemo) {
    return <></>;
  }

  return (
    <Alert variant="light" className="mt-4 mb-0 py-6" show={show} dismissible onClose={handleClose}>
      <div className="text-center" style={{ paddingLeft: "3rem", paddingRight: "1.5rem" }}>
        <h2>
          Just having a look around?<span className="d-none d-sm-inline"> 👀</span>
        </h2>
        <div style={{ margin: "0 -1.5rem" }}>
          Our demo data allows you to explore Apitally before setting up your own app.
        </div>
        <div className="mt-6">
          <Button variant="secondary" onClick={() => launchDemo()}>
            Explore with demo data
          </Button>
        </div>
      </div>
    </Alert>
  );
}

export default LaunchDemoAlert;
