import {
  faDoorOpen,
  faEnvelope,
  faExclamationTriangle,
  faEye,
  faPeopleGroup,
  faUserNinja,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReferenceLine, SparklinesLine } from "@lueton/react-sparklines";
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Placeholder from "react-bootstrap/Placeholder";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import capitalize from "lodash/capitalize";
import "./AdminTeams.css";
import { ListTeamsAdminResponseItem } from "./backend";
import MainLayout from "./components/MainLayout";
import PageHeader from "./components/PageHeader";
import TableCard from "./components/TableCard";
import TableCardSearchHeader from "./components/TableCardSearchHeader";
import TableCellDropdown from "./components/TableCellDropdown";
import Tooltip from "./components/Tooltip";
import { useGlobal } from "./contexts/GlobalContext";
import AdminTeamDetailsModal from "./modals/AdminTeamDetailsModal";
import { getColor } from "./utils/colors";

function AdminTeams() {
  const { backendClient, teams, refreshTeams, setActiveTeam, setActiveTeamIdAfterRefresh, timezone } = useGlobal();
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState<ListTeamsAdminResponseItem[]>();
  const [teamInfoModalTeam, setTeamInfoModalTeam] = useState<ListTeamsAdminResponseItem | undefined>();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Admin - Teams - Apitally";
  }, []);

  const queryParams = { timezone };
  const query = useQuery({
    queryKey: ["adminTeams", queryParams],
    queryFn: () => backendClient!.admin.listTeamsAdmin(queryParams),
    enabled: !!backendClient,
    staleTime: 60 * 60 * 1000, // 1 hour
  });

  useEffect(() => {
    if (search) {
      const searchTerms = search.toLowerCase().trim().split(/\s+/);
      setSearchResult(
        query.data?.filter((team) =>
          searchTerms.every(
            (term) =>
              team.name.toLowerCase().includes(term) ||
              team.contact?.name.toLowerCase().includes(term) ||
              team.contact?.email.toLowerCase().includes(term) ||
              team.plan.name.toLowerCase().includes(term),
          ),
        ),
      );
    } else {
      setSearchResult(query?.data);
    }
  }, [search, query.data]);

  const joinTeam = async (teamId: number) => {
    const existingTeam = teams?.find((team) => team.id === teamId);
    if (existingTeam) {
      setActiveTeam(existingTeam);
    } else if (backendClient) {
      const promise = backendClient.users.joinTeamStealth({
        requestBody: {
          team_id: teamId,
        },
      });
      toast.promise(promise, {
        pending: "Joining team...",
        success: "Team joined!",
        error: "Failed to join team.",
      });
      await promise;
      setActiveTeamIdAfterRefresh(teamId);
      await refreshTeams();
    }
    navigate("/apps");
  };

  const leaveTeam = async (teamId: number) => {
    if (backendClient) {
      const promise = backendClient.users.leaveTeam({ teamId });
      toast.promise(promise, {
        pending: "Leaving team...",
        success: "Left team!",
        error: "Failed to leave team.",
      });
      await promise;
      refreshTeams();
    }
  };

  const teamRows = searchResult?.map((team) => {
    const currentInstanceCount = team.instance_count_history.at(-1);
    const currentInstanceCountOverLimit =
      team.plan.limit_instances && currentInstanceCount && currentInstanceCount > team.plan.limit_instances;
    const anyInstanceCountOverLimit = team.instance_count_history.some(
      (count) => team.plan.limit_instances && count > team.plan.limit_instances,
    );
    const maxInstanceCount = Math.max(...team.instance_count_history);
    const lastLoginDate = team.last_login ? DateTime.fromISO(team.last_login) : undefined;
    const loginCount = team.login_count_history.reduce((a, b) => a + b, 0);
    const lineColor = getColor(
      team.active_instances ? (currentInstanceCountOverLimit ? "danger" : "primary") : "secondary",
    );
    const joined = !!teams?.find((t) => t.id === team.id && !t.stealth);
    const joinedInStealth = !!teams?.find((t) => t.id === team.id && t.stealth);

    return (
      <tr key={team.id}>
        <td width={44}>
          <Tooltip
            condition={team.plan.paid && team.plan.subscription_status !== "active"}
            tooltip={`Subscription status: ${capitalize(team.plan.subscription_status?.replaceAll("_", " "))}`}
          >
            <FontAwesomeIcon
              icon={faPeopleGroup}
              className={classNames(
                {
                  "text-very-muted": !team.plan.paid,
                  "text-primary": team.plan.paid && team.plan.subscription_status === "active",
                  "text-danger": team.plan.paid && team.plan.subscription_status !== "active",
                },
                "ms-2",
              )}
            />
          </Tooltip>
        </td>
        <td>
          <div>
            <Button variant="link" className="border-0" onClick={() => joinTeam(team.id)}>
              {team.name}
            </Button>
          </div>
          <div className="small text-muted text-nowrap">
            {team.plan.name}
            <span className="mx-1" style={{ opacity: 0.5 }}>
              /
            </span>
            {team.apps.length} app{team.apps.length != 1 ? "s" : ""}
            {team.user_count > 1 && (
              <>
                <span className="mx-1" style={{ opacity: 0.5 }}>
                  /
                </span>
                {team.user_count} user{team.user_count != 1 ? "s" : ""}
              </>
            )}
          </div>
        </td>
        <td>
          <div className="text-nowrap">{team.contact?.name}</div>
          <div className="small text-muted text-nowrap">{team.contact?.email}</div>
        </td>
        <td>
          {lastLoginDate && (
            <>
              <div>
                <span title={lastLoginDate.toLocaleString(DateTime.DATETIME_FULL)} className="text-nowrap">
                  {lastLoginDate.toRelative()}
                </span>
              </div>
              <div className="small text-muted text-nowrap">{loginCount} logins in 30 days</div>
            </>
          )}
        </td>
        <td>
          <Tooltip
            placement="left"
            tooltip={
              <>
                {currentInstanceCount} instance{currentInstanceCount != 1 ? "s" : ""} today.
                <br />
                Peak was {maxInstanceCount} in last 30 days.
              </>
            }
          >
            <div style={{ width: 200 }}>
              <SparklinesLine
                data={team.instance_count_history}
                min={0}
                max={
                  team.plan.limit_instances && team.plan.limit_instances > maxInstanceCount
                    ? team.plan.limit_instances
                    : maxInstanceCount
                }
                fill={lineColor}
                fillOpacity={0.1}
                stroke={lineColor}
                strokeWidth={0.75}
                margin={0}
                width={200}
                height={45}
              >
                {anyInstanceCountOverLimit && team.plan.limit_instances && (
                  <ReferenceLine
                    y={team.plan.limit_instances}
                    stroke={getColor("danger")}
                    strokeWidth={1}
                    strokeDasharray="4,2"
                  />
                )}
              </SparklinesLine>
            </div>
          </Tooltip>
        </td>
        <td width={60} className="pe-2">
          <div className="d-flex flex-row align-items-center justify-content-end">
            {joinedInStealth && <FontAwesomeIcon icon={faUserNinja} className="text-very-muted me-2" />}
            {team.plan_limits_status !== "ok" && (
              <Tooltip tooltip={team.plan_limits_status === "grace" ? "In grace period" : "Exceeding plan limits"}>
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className={classNames(
                    {
                      "text-very-muted": team.plan_limits_status === "grace",
                      "text-danger": team.plan_limits_status === "exceeded",
                    },
                    "me-2",
                  )}
                />
              </Tooltip>
            )}
          </div>
        </td>
        <TableCellDropdown>
          <Dropdown.Item as="button" onClick={() => setTeamInfoModalTeam(team)}>
            <FontAwesomeIcon icon={faEye} fixedWidth className="text-secondary" />
            Team details
          </Dropdown.Item>
          <Dropdown.Item
            as="button"
            onClick={() => {
              window.location.href = `mailto:${team.contact?.email}`;
            }}
          >
            <FontAwesomeIcon icon={faEnvelope} fixedWidth className="text-secondary" />
            Send email
          </Dropdown.Item>
          {!joined && !joinedInStealth && (
            <>
              <Dropdown.Divider />
              <Dropdown.Item as="button" onClick={() => joinTeam(team.id)}>
                <FontAwesomeIcon icon={faUserNinja} fixedWidth className="text-secondary" />
                Join team in stealth
              </Dropdown.Item>
            </>
          )}
          {joinedInStealth && (
            <>
              <Dropdown.Divider />
              <Dropdown.Item as="button" onClick={() => leaveTeam(team.id)}>
                <FontAwesomeIcon icon={faDoorOpen} fixedWidth className="text-secondary" />
                Leave team
              </Dropdown.Item>
            </>
          )}
        </TableCellDropdown>
      </tr>
    );
  });

  const searchHeader = (
    <TableCardSearchHeader search={search} setSearch={setSearch} placeholder="Search teams" paddingLeft={51} />
  );
  const tableFooter = searchResult && searchResult.length > 0 && query.isSuccess && (
    <div className="small text-muted">
      Showing {searchResult.length} of {query.data.length} teams
    </div>
  );

  return (
    <MainLayout>
      <AdminTeamDetailsModal team={teamInfoModalTeam} setTeam={setTeamInfoModalTeam} />
      <div className="AdminTeams">
        <PageHeader noDemoBadge>
          <>Admin</>
          <>Teams</>
        </PageHeader>
        <TableCard responsive header={searchHeader} footer={tableFooter} className="align-middle">
          <thead>
            <tr>
              <th style={{ width: 44 }}></th>
              <th>Team name</th>
              <th>Main contact</th>
              <th>User logins</th>
              <th>App instances</th>
              <th></th>
              <th style={{ width: 44 }}></th>
            </tr>
          </thead>
          <tbody>
            {teamRows}
            {!teamRows && (
              <Placeholder as="tr" animation="glow">
                <td width={44}></td>
                <td>
                  <div className="text-primary">
                    <Placeholder xs={6} />
                  </div>
                  <div className="small text-body-secondary">
                    <Placeholder xs={8} />
                  </div>
                </td>
                <td>
                  <div className="text-secondary">
                    <Placeholder xs={8} />
                  </div>
                  <div className="small text-body-secondary">
                    <Placeholder xs={10} />
                  </div>
                </td>
                <td>
                  <div className="text-secondary">
                    <Placeholder xs={4} />
                  </div>
                  <div className="small text-body-secondary">
                    <Placeholder style={{ width: 155 }} />
                  </div>
                </td>
                <td></td>
                <td width={40}></td>
              </Placeholder>
            )}
            {query.isSuccess && query.data.length > 0 && search && searchResult && searchResult.length === 0 && (
              <tr>
                <td className="d-none d-lg-table-cell" width={44}></td>
                <td colSpan={4} className="text-center py-6">
                  Your search didn't match any teams.
                </td>
                <td className="d-none d-lg-table-cell" width={44}></td>
              </tr>
            )}
          </tbody>
        </TableCard>
      </div>
    </MainLayout>
  );
}

export default AdminTeams;
