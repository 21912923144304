import { useQuery } from "@tanstack/react-query";
import {
  ChartData,
  Chart as ChartJS,
  Filler,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  TimeSeriesScale,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-luxon";
import { rgba } from "polished";
import { memo } from "react";
import { Line } from "react-chartjs-2";
import { merge } from "ts-deepmerge";

import { AppEnvItem, ListAppsResponseItem } from "../backend";
import { useGlobal } from "../contexts/GlobalContext";
import { Endpoint } from "../types/Endpoint";
import { getChartOptions } from "../utils/charts";
import { getColor } from "../utils/colors";

ChartJS.register(Filler, LinearScale, TimeSeriesScale, LineController, LineElement, PointElement, Title, Tooltip);

type RequestsPerMinuteLineChartProps = {
  app: ListAppsResponseItem;
  env?: AppEnvItem;
  consumerId?: number;
  consumerGroupId?: number;
  endpointGroupId?: number;
  endpoint?: Endpoint;
  period: string;
  setPeriod?: (period: string) => void;
  displayTitle?: boolean;
};

function RequestsPerMinuteLineChart({
  app,
  env,
  consumerId,
  consumerGroupId,
  endpointGroupId,
  period,
  setPeriod,
  displayTitle = true,
}: RequestsPerMinuteLineChartProps) {
  const { backendClient, timezone } = useGlobal();

  const queryParams = {
    appId: app.id,
    appEnv: env?.slug,
    consumerId,
    consumerGroupId,
    endpointGroupId,
    period,
    timezone,
  };
  const { data, isSuccess } = useQuery({
    queryKey: ["requestsPerMinuteChart", queryParams],
    queryFn: () => backendClient!.traffic.getRequestsPerMinuteChart(queryParams),
    enabled: !!backendClient,
  });

  if (isSuccess) {
    const color = rgba(getColor("primary"), 0.6);
    const chartOptions = merge(
      getChartOptions({
        labels: data.time_windows,
        title: displayTitle ? "Requests per minute" : undefined,
        setPeriod,
      }),
      {
        interaction: { mode: "index" as const, intersect: false },
      },
    );
    const chartData: ChartData<"line"> = {
      labels: data.time_windows,
      datasets: [
        {
          label: "Requests per minute",
          data: data.requests_per_minute,
          borderWidth: 0,
          fill: true,
          borderColor: color,
          backgroundColor: color,
          pointStyle: false,
        },
      ],
    };
    return (
      <div style={{ position: "relative", height: "220px", width: "100%" }}>
        <Line data={chartData} options={chartOptions} />
      </div>
    );
  } else {
    return <></>;
  }
}

export default memo(RequestsPerMinuteLineChart);
