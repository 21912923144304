import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import {
  faBoltLightning,
  faBug,
  faBullseye,
  faChartSimple,
  faEye,
  faRotateRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIsFetching, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Placeholder from "react-bootstrap/Placeholder";
import Stack from "react-bootstrap/Stack";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";

import "./EndpointGroups.css";
import { ListEndpointGroupsWithTrafficResponseItem } from "./backend";
import AppDropdown from "./components/AppDropdown";
import AppEnvDropdown, { AppEnvDropdownItems } from "./components/AppEnvDropdown";
import CustomDropdown from "./components/Dropdown";
import FilterBadges from "./components/FilterBadges";
import MainLayout from "./components/MainLayout";
import PageHeader from "./components/PageHeader";
import PageSpinner from "./components/PageSpinner";
import Pagination from "./components/Pagination";
import PeriodDropdown, { PeriodDropdownItems } from "./components/PeriodDropdown";
import PlanLimitsExceededAlert from "./components/PlanLimitsExceededAlert";
import RefreshButton from "./components/RefreshButton";
import SparklineGraph from "./components/SparklineGraph";
import TableCard, { ColumnHeader, SortBy } from "./components/TableCard";
import TableCardSearchHeader from "./components/TableCardSearchHeader";
import TableCellDropdown from "./components/TableCellDropdown";
import TableCellWithBar from "./components/TableCellWithBar";
import TeaserCard from "./components/TeaserCard";
import Tooltip from "./components/Tooltip";
import { useGlobal } from "./contexts/GlobalContext";
import useDashboardFilters from "./hooks/useDashboardFilters";
import EndpointGroupDetailsModal from "./modals/EndpointGroupDetailsModal";
import { formatPeriod } from "./utils/period";

function EndpointGroupsTeaser() {
  const { launchDemo } = useGlobal();

  const pageHeaderButtons = (
    <Button variant="outline-light" className="float-end" onClick={() => launchDemo()}>
      Explore with demo data
    </Button>
  );
  return (
    <MainLayout>
      <PageHeader buttons={pageHeaderButtons}>
        <>Endpoint groups</>
        <span className="text-muted">No apps set up yet</span>
      </PageHeader>
      <TeaserCard icon={faBullseye}>
        <h2>Understand usage of different endpoint groups</h2>
        <p className="mt-4">
          Apitally automatically groups endpoints based on common path prefixes, allowing you to understand how
          different parts of your API are being used.
        </p>
        <p className="mt-4">
          On this page you'll see a list of all endpoint groups, along with a graph of their recent requests and
          additional details. From here you can jump to the filtered traffic page for further insights.
        </p>
      </TeaserCard>
    </MainLayout>
  );
}

function EndpointGroups() {
  const { apps, activeApp, backendClient } = useGlobal();
  const [sortedData, setSortedData] = useState<ListEndpointGroupsWithTrafficResponseItem[]>();
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState<ListEndpointGroupsWithTrafficResponseItem[]>();
  const [sortBy, setSortBy] = useState<SortBy>({ column: "level", direction: "asc" });
  const [page, setPage] = useState(1);
  const [endpointGroupDetailsModalEndpointGroup, setEndpointGroupDetailsModalEndpointGroup] =
    useState<ListEndpointGroupsWithTrafficResponseItem>();
  const { app, appSlug, env, setEnv, period, setPeriod } = useDashboardFilters();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const isLoading = useIsFetching() > 0;
  const itemsPerPage = 25;

  useEffect(() => {
    if (activeApp) {
      document.title = `Endpoint groups - ${activeApp.name} - Apitally`;
    } else {
      document.title = `Endpoint groups - Apitally`;
    }
  }, [activeApp]);

  const queryParams = {
    appId: app?.id || 0,
    appEnv: env?.slug,
    period: period,
  };
  const query = useQuery({
    queryKey: ["endpointGroupsWithTraffic", queryParams],
    queryFn: () => backendClient!.endpoints.listEndpointGroupsWithTraffic(queryParams),
    enabled: !!backendClient && !!app,
  });
  const requestsSparklinesQuery = useQuery({
    queryKey: ["endpointGroupRequestsSparklines", queryParams],
    queryFn: () => backendClient!.traffic.getEndpointGroupRequestsSparklines(queryParams),
    enabled: !!backendClient && !!app,
  });

  const levelLocalStorageKey = `endpoint-groups-level:${app?.id}`;
  const levelSearchParam = searchParams.get("level") || localStorage.getItem(levelLocalStorageKey) || "0";
  const level = parseInt(levelSearchParam, 10) || undefined;
  const setLevel = (level?: number) => {
    setSearchParams(
      (searchParams) => {
        if (level) {
          searchParams.set("level", level.toString());
        } else {
          searchParams.delete("level");
        }
        return searchParams;
      },
      { replace: true },
    );
    if (level) {
      localStorage.setItem(levelLocalStorageKey, level.toString());
    } else {
      localStorage.removeItem(levelLocalStorageKey);
    }
  };

  useEffect(() => {
    setSortedData(query.data ? sortData(query.data, sortBy) : undefined);
  }, [query.data, sortBy]);

  useEffect(() => {
    const filteredData = level ? sortedData?.filter((item) => item.level === level) : sortedData;
    if (search) {
      const searchTerms = search.toLowerCase().trim().split(/\s+/);
      setSearchResult(
        filteredData?.filter((item) => searchTerms.every((term) => item.name.toLowerCase().includes(term))),
      );
    } else {
      setSearchResult(filteredData);
    }
    setPage(1);
  }, [level, search, sortedData]);

  useEffect(() => {
    setSearch("");
    setPage(1);
  }, [app, env]);

  const refresh = async () => {
    await queryClient.refetchQueries({ type: "active" });
  };

  const sortData = <T extends ListEndpointGroupsWithTrafficResponseItem>(data: T[], sortBy: SortBy): T[] => {
    const sort = (a: T, b: T) => {
      const sign = sortBy.direction === "asc" ? 1 : -1;
      if (sortBy.column === "name") {
        return a.name.localeCompare(b.name) * sign;
      } else if (sortBy.column === "level") {
        return (a.level - b.level) * sign;
      } else if (sortBy.column === "requests") {
        return (a.requests - b.requests) * sign;
      } else if (sortBy.column === "error_rate") {
        return ((a.error_rate || 0) - (b.error_rate || 0)) * sign;
      } else {
        return 0;
      }
    };
    return data?.slice().sort(sort);
  };

  if (!apps) {
    return (
      <MainLayout>
        <PageSpinner />
      </MainLayout>
    );
  } else if (apps.length === 0) {
    return <EndpointGroupsTeaser />;
  } else if (!appSlug) {
    return (
      <MainLayout>
        <Navigate to={`/endpoint-groups/${activeApp ? activeApp.slug : apps[0].slug}`} />
      </MainLayout>
    );
  } else if (!app) {
    return (
      <MainLayout>
        <Navigate to="/apps" />
      </MainLayout>
    );
  }

  const maxRequestCount = searchResult?.reduce((max, item) => Math.max(max, item.requests), 0);
  const maxErrorRate = searchResult?.reduce((max, item) => Math.max(max, item.error_rate || 0), 0);
  const endpointGroupRows = searchResult?.slice(itemsPerPage * (page - 1), itemsPerPage * page).map((endpointGroup) => {
    const linkQueryParams = new URLSearchParams();
    linkQueryParams.set("period", period);
    linkQueryParams.set("endpoint_group", endpointGroup.id.toString());
    if (env) {
      linkQueryParams.set("env", env.slug);
    }
    const trafficLink = `/traffic/${app?.slug}?${linkQueryParams}`;
    const errorsLink = `/errors/${app?.slug}?${linkQueryParams}`;
    const performanceLink = `/performance/${app?.slug}?${linkQueryParams}`;

    return (
      <tr key={`endpoint-group-${endpointGroup.id}`}>
        <td width={40}>
          <FontAwesomeIcon icon={faBullseye} className="ms-2 text-very-muted" />
        </td>
        <td>
          <div>
            <Link to={trafficLink}>{endpointGroup.name}</Link>
          </div>
          <div className="small text-muted">
            {endpointGroup.endpoints} endpoint{endpointGroup.endpoints != 1 ? "s" : ""}
          </div>
        </td>
        <td>
          <Tooltip placement="right" tooltip={`Click to filter table to level ${endpointGroup.level}`}>
            <Badge
              bg="secondary"
              className="outline-secondary cursor-pointer"
              onClick={() => setLevel(endpointGroup.level)}
            >
              {endpointGroup.level}
            </Badge>
          </Tooltip>
        </td>
        <td width={360}>
          <SparklineGraph
            data={requestsSparklinesQuery.data?.[endpointGroup.id]}
            linkTo={trafficLink}
            width={280}
            marginRight={20}
          />
        </td>
        <TableCellWithBar
          showBar={sortBy.column !== "error_rate"}
          value={endpointGroup.requests}
          maxValue={maxRequestCount}
        >
          {endpointGroup.requests.toLocaleString()}
        </TableCellWithBar>
        <TableCellWithBar
          showBar={sortBy.column === "error_rate"}
          value={endpointGroup.error_rate}
          maxValue={maxErrorRate}
        >
          {endpointGroup.error_rate
            ?.toLocaleString(undefined, { style: "percent", minimumFractionDigits: 1 })
            .replace("%", " %") || "-"}
        </TableCellWithBar>
        <TableCellDropdown>
          <Dropdown.Item as="button" onClick={() => setEndpointGroupDetailsModalEndpointGroup(endpointGroup)}>
            <FontAwesomeIcon icon={faEye} fixedWidth className="text-secondary" />
            Endpoint group details
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Header>Filter dashboards</Dropdown.Header>
          <Dropdown.Item as="button" onClick={() => navigate(trafficLink)}>
            <FontAwesomeIcon icon={faChartSimple} fixedWidth className="text-secondary" />
            Traffic
          </Dropdown.Item>
          <Dropdown.Item as="button" onClick={() => navigate(errorsLink)}>
            <FontAwesomeIcon icon={faBug} fixedWidth className="text-secondary" />
            Errors
          </Dropdown.Item>
          <Dropdown.Item as="button" onClick={() => navigate(performanceLink)}>
            <FontAwesomeIcon icon={faBoltLightning} fixedWidth className="text-secondary" />
            Performance
          </Dropdown.Item>
        </TableCellDropdown>
      </tr>
    );
  });

  const getEmptyTableMessageRow = (message: React.ReactNode) => {
    return (
      <tr>
        <td colSpan={6} className="text-center py-6 px-4">
          {message}
        </td>
      </tr>
    );
  };

  const levels = query.data
    ?.map((item) => item.level)
    .filter((value, index, self) => self.indexOf(value) === index) // make unique
    .sort((a, b) => a - b);
  const levelDropdownItems = levels?.map((lvl) => (
    <Dropdown.Item key={lvl} eventKey={lvl.toString()} active={lvl === level}>
      Level {lvl}
    </Dropdown.Item>
  ));
  const pageHeaderButtons = [
    <PeriodDropdown key="period-dropdown" align="end" period={period} setPeriod={setPeriod} />,
    <AppEnvDropdown key="app-env-dropdown" appEnv={env} setAppEnv={setEnv} />,
    <CustomDropdown
      key="level-dropdown"
      icon="/icons/filter-regular.svg"
      title={level ? `Level ${level}` : "All levels"}
      onSelect={(key: string) => setLevel(key === "0" ? undefined : parseInt(key, 10))}
    >
      <Dropdown.Menu className="lh-base">
        <Dropdown.Item eventKey="0" active={!level}>
          All levels
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Header>Filter by level</Dropdown.Header>
        {levelDropdownItems}
      </Dropdown.Menu>
    </CustomDropdown>,
    <RefreshButton key="refresh-button" onClick={refresh} loading={isLoading} />,
  ];
  const pageHeaderDropdown = (
    <Dropdown.Menu>
      <Dropdown.Item as="button" disabled={isLoading} onClick={refresh}>
        <FontAwesomeIcon icon={faRotateRight} fixedWidth />
        Refresh
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Header>Select period</Dropdown.Header>
      <PeriodDropdownItems period={period} setPeriod={setPeriod} />
      <Dropdown.Divider />
      <Dropdown.Header>Filter by environment</Dropdown.Header>
      <AppEnvDropdownItems appEnv={env} setAppEnv={setEnv} />
    </Dropdown.Menu>
  );
  const searchHeader =
    query.isSuccess && query.data.length > 5 ? (
      <TableCardSearchHeader search={search} setSearch={setSearch} placeholder="Search endpoint groups" />
    ) : undefined;
  let tableFooter = undefined;
  if (query.isSuccess && query.data.length > itemsPerPage && searchResult && searchResult.length > 0) {
    const showingItemsFrom = itemsPerPage * (page - 1) + 1;
    const showingItemsTo = Math.min(itemsPerPage * page, searchResult.length);
    const numberOfPages = Math.ceil(searchResult.length / itemsPerPage);
    tableFooter = (
      <Stack direction="horizontal">
        <div className="small text-muted">
          Showing endpoint groups {showingItemsFrom.toLocaleString()}-{showingItemsTo.toLocaleString()} of{" "}
          {searchResult.length.toLocaleString()}
        </div>
        <div className="ms-auto">
          <Pagination page={page} numberOfPages={numberOfPages} setPage={setPage} />
        </div>
      </Stack>
    );
  }

  const graphColumnInfo = (
    <Tooltip placement="bottom" tooltip={`Showing requests over the last ${formatPeriod(period)}`}>
      <FontAwesomeIcon icon={faCircleQuestion} className="ms-2 text-body-tertiary" />
    </Tooltip>
  );

  return (
    <MainLayout>
      <div className="EndpointGroups">
        <EndpointGroupDetailsModal
          endpointGroup={endpointGroupDetailsModalEndpointGroup}
          setEndpointGroup={setEndpointGroupDetailsModalEndpointGroup}
          period={period}
        />
        <PageHeader breakpoint="xl" buttons={pageHeaderButtons} dropdownMenu={pageHeaderDropdown}>
          <>Endpoint groups</>
          <AppDropdown onSelect={(appSlug) => appSlug !== null && navigate(`/endpoint-groups/${appSlug}`)} />
        </PageHeader>
        <PlanLimitsExceededAlert />
        <FilterBadges
          app={app}
          period={period}
          env={env?.slug}
          clearEnv={() => setEnv(undefined)}
          level={level}
          clearLevel={() => setLevel(undefined)}
        />
        <TableCard
          responsive
          header={searchHeader}
          footer={tableFooter}
          hover={endpointGroupRows && endpointGroupRows.length > 0}
        >
          <thead>
            <tr className={!endpointGroupRows?.length ? "d-none d-md-table-row" : undefined}>
              <th></th>
              <ColumnHeader
                name="name"
                sortBy={sortBy}
                setSortBy={setSortBy}
                defaultSortDirection="asc"
                className="name-column"
              >
                Endpoint group
              </ColumnHeader>
              <ColumnHeader name="level" sortBy={sortBy} setSortBy={setSortBy} defaultSortDirection="asc">
                Level
              </ColumnHeader>
              <th>Graph{graphColumnInfo}</th>
              <ColumnHeader name="requests" sortBy={sortBy} setSortBy={setSortBy} defaultSortDirection="desc">
                Requests
              </ColumnHeader>
              <ColumnHeader name="error_rate" sortBy={sortBy} setSortBy={setSortBy} defaultSortDirection="desc">
                Error rate
              </ColumnHeader>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {endpointGroupRows}
            {!endpointGroupRows && (
              <Placeholder as="tr" animation="glow">
                <td width={40}></td>
                <td>
                  <div className="text-primary">
                    <Placeholder xs={6} />
                  </div>
                  <div className="small text-body-secondary">
                    <Placeholder xs={4} />
                  </div>
                </td>
                <td>
                  <Placeholder xs={4} />
                </td>
                <td width={400}></td>
                <td>
                  <Placeholder xs={4} />
                </td>
                <td>
                  <Placeholder xs={4} />
                </td>
                <td width={40}></td>
              </Placeholder>
            )}
            {query.isSuccess &&
              query.data.length === 0 &&
              getEmptyTableMessageRow(<>This app doesn't have any endpoint groups yet.</>)}
            {query.isSuccess &&
              query.data.length > 0 &&
              search &&
              searchResult &&
              searchResult.length === 0 &&
              getEmptyTableMessageRow("Your search didn't match any endpoint groups.")}
          </tbody>
        </TableCard>
      </div>
    </MainLayout>
  );
}

export default EndpointGroups;
