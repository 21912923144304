import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { AppEnvItem } from "../backend";
import { useGlobal } from "../contexts/GlobalContext";
import { getInitialPeriod, isCustomPeriod, validatePeriod } from "../utils/period";

function useDashboardFilters() {
  const { appSlug } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { apps, setActiveApp } = useGlobal();

  const app = apps?.find((app) => app.slug === appSlug);

  const envLocalStorageKey = `env:${app?.id}`;
  const envSlug = searchParams.get("env") || localStorage.getItem(envLocalStorageKey) || undefined;
  const env = app?.envs.find((env) => env.slug === envSlug);
  const setEnv = (env?: AppEnvItem) => {
    setSearchParams(
      (searchParams) => {
        if (env) {
          searchParams.set("env", env.slug);
        } else {
          searchParams.delete("env");
        }
        return searchParams;
      },
      { replace: true },
    );
    if (env && env.active) {
      localStorage.setItem(envLocalStorageKey, env.slug);
    } else {
      localStorage.removeItem(envLocalStorageKey);
    }
  };

  const setPeriod = (period: string) => {
    setSearchParams(
      (searchParams) => {
        searchParams.set("period", period);
        return searchParams;
      },
      { replace: true },
    );
    if (!isCustomPeriod(period)) {
      localStorage.setItem("period", period);
    }
  };
  const resetPeriod = () => {
    setPeriod(getInitialPeriod());
  };
  let period = searchParams.get("period");
  if (period && !validatePeriod(period)) {
    period = getInitialPeriod();
    setPeriod(period);
  } else if (!period) {
    period = getInitialPeriod();
  }

  const consumerSearchParam = searchParams.get("consumer") || "0";
  const consumerId = parseInt(consumerSearchParam, 10) || undefined;
  const setConsumerId = (consumerId?: number) => {
    setSearchParams(
      (searchParams) => {
        if (consumerId) {
          searchParams.set("consumer", consumerId.toString());
        } else {
          searchParams.delete("consumer");
        }
        return searchParams;
      },
      { replace: true },
    );
  };

  const consumerGroupSearchParam = searchParams.get("consumer_group") || "0";
  const consumerGroupId = parseInt(consumerGroupSearchParam, 10) || undefined;
  const setConsumerGroupId = (consumerGroupId?: number) => {
    setSearchParams(
      (searchParams) => {
        if (consumerGroupId) {
          searchParams.set("consumer_group", consumerGroupId.toString());
        } else {
          searchParams.delete("consumer_group");
        }
        return searchParams;
      },
      { replace: true },
    );
  };

  const endpointGroupSearchParam = searchParams.get("endpoint_group") || "0";
  const endpointGroupId = parseInt(endpointGroupSearchParam, 10) || undefined;
  const setEndpointGroupId = (endpointGroupId?: number) => {
    setSearchParams(
      (searchParams) => {
        if (endpointGroupId) {
          searchParams.set("endpoint_group", endpointGroupId.toString());
        } else {
          searchParams.delete("endpoint_group");
        }
        return searchParams;
      },
      { replace: true },
    );
  };

  useEffect(() => {
    if (app) {
      setActiveApp(app);
    }
  }, [app]);

  useEffect(() => {
    if (appSlug) {
      setPeriod(period);
      if (!envSlug || env) {
        setEnv(env);
      }
    }
  }, [appSlug]);

  return {
    appSlug,
    app,
    env,
    setEnv,
    period,
    setPeriod,
    resetPeriod,
    consumerId,
    setConsumerId,
    consumerGroupId,
    setConsumerGroupId,
    endpointGroupId,
    setEndpointGroupId,
  };
}

export default useDashboardFilters;
