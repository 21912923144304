import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import classNames from "classnames";
import "./Metric.css";
import Tooltip from "./Tooltip";

type MetricProps = {
  label: string;
  value?: React.ReactNode;
  help?: React.ReactNode;
  helpOnClick?: () => void;
};

function Metric({ label, value, help, helpOnClick }: MetricProps) {
  const helpIcon = (
    <>
      {help && (
        <Tooltip placement="bottom" tooltip={help}>
          <small>
            <FontAwesomeIcon
              icon={faCircleQuestion}
              className={classNames("ms-2 text-body-tertiary", { "cursor-pointer": helpOnClick })}
              onClick={helpOnClick}
            />
          </small>
        </Tooltip>
      )}
    </>
  );

  return (
    <div className="Metric">
      <div className="metric-label">
        {label}
        {helpIcon}
      </div>
      <div className="metric-value">{value || "-"}</div>
    </div>
  );
}

export default Metric;
